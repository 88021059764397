.floatnav {
  @include spacing(2);
  padding: 0 1rem;
  list-style: none;
  z-index: 3;
  @include mq(48em) {
    position: absolute;
    top: 2rem;
    right: 0;
    margin: 0;
    padding: 0;
    .hero--image &,
    .hero-slider-alt & {
      position: static;
      max-width: 300px;
    }
  }

  @include mq(90em) {
    top: 3rem;
  }
}

.floatnav--module {
  @include mq(48em) {
    @include spacing(4);
    position: static;
    padding: 0 1rem;
  }
  @include mq(64.0625em) {
    text-align: center;
  }
  @include mq(90em) {
    position: absolute;
    top: 5rem;
    right: 0;
    margin: 0;
    padding: 0;
  }
}

.floatnav__item {
  @include mq(48em) {
    margin: 0.25rem 0;
    .hero--image &,
    .hero-slider-alt & {
      margin-bottom: .75rem;
    }
  }

  .floatnav--module & {
    @include mq(48em) {
      margin: 0.75rem 0;
    }
    @include mq(64.0625em) {
      display: inline-block;
      margin: 0 0.625rem;
    }
    @include mq(90em) {
      display: block;
      margin: 0.25rem 0;
    }
  }
}

.floatnav__link {
  width: 100%;
  text-align: center;

  .floatnav--module & {
    border-color: transparent;
    background: $white;

    @include mq(48em) {
      border-radius: 0.1875rem;
      text-align: center;
    }
    @include mq(90em) {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      text-align: left;
    }
  }
}
