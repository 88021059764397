.title {
  @include spacing(2);
  text-align: center;

  @include mq(48em) {
    @include clear; // ie9
    display: flex;
    align-items: center;
    justify-content: center;
  }
  @include mq(64.0625em) {
    @include spacing(4);
  }
}

.title + .story-slider-container,
.title + .image-slider-container {
  @include mq(64.0625em) {
    margin-top: -2.5rem !important;
  }
}

.title__logo {
  width: 3rem;
  margin: 0 auto 1.25rem;

  @include mq(48em) {
    width: 4rem;
    margin: 0 1.25rem 0 0;
    float: left; // ie9
  }
}

.title__text {
  margin: 0;

  @include mq(48em) {
    max-width: calc(100% - 5.25rem);
    margin: 0;
    float: left; // ie9
    // text-align: left;
  }
  a {
    color: inherit;
  }
}

.title__text--alt {
  text-align: center;
  h1 {
    margin: 0 0 .25rem 0;
  }
}
