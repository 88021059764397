.nav-fixed {
  &.nav-search-active {
    .search--nav {
      @include show;
      visibility: visible;
    }
  }
}

.search {
  @include hide;
  padding: 1rem 0;
  background: $beige;
  color: $black;
  visibility: hidden;

  @include mq(48em) {
    padding: 2rem 0;
  }

  .search-active & {
    @include show;
    visibility: visible;
  }
}

.search--nav {
  .search-active & {
    @include hide;
    visibility: hidden;
  }
}

.search__form {
  @include clear;
  position: relative;
  legend {
    @include hide;
  }
}

.search__input {
  display: block;
  width: 100%;
  height: 3rem;
  padding: 0.75rem 3rem 0.75rem 0.75rem;
  border: 0.0625rem solid $black;
  background: $white;
  font-family: $sans-serif;
  font-size: 1rem;
  font-weight: 500;
  line-height: normal;
}

.search__button {
  position: absolute;
  top: 0;
  right: 0;
  width: 3rem;
  padding: 0.6875rem;
  overflow: hidden;
}

.search__option {
  margin-top: 1rem;
  margin-right: 2.5rem;
  float: left;
  line-height: 1;
}

.search__radio {
  margin-right: 0.25rem;
  vertical-align: middle;
}

.search__label {
  font-family: $sans-serif;
  font-size: 0.75rem;
  text-transform: uppercase;
  font-weight: 600;
}
