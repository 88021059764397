.story-slider-container,
.story-shift-container {
  @include spacing(4);
  margin: -0.375rem;
  padding: 0.375rem;
  overflow: hidden;
  
  &:first-child,
  &:last-child {
    margin: -0.375rem;
  }
}

.story-slider,
.story-shift {
  margin: 0;
  padding: 0;
  list-style: none;
  .flickity-viewport {
    overflow: visible;
  }
}

.story {
  @include clear;
  width: 100%;
  margin: 0 0.625rem;
  background: $white;
  box-shadow: 0 0 0.125rem 0.125rem $black-shadow;
  
  @include mq(48em) {
    display: flex;
  }
}

.story__media {
  @include mq(48em) {
    position: relative;
    width: 50%;
    float: left;
  }
  
  @include mq(64.0625em) {
    width: 66.6667%;
  }
  
  &::before {
    @include mq(48em) {
      display: block;
      padding-bottom: 66.6667%;
      content: '';
    }
  }
}

.story__image {
  @include mq(48em) {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    @supports (object-fit: cover) {
      height: 100%;
      object-fit: cover;
    }
  }
}

.story__text {
  @include zero;
  position: relative;
  padding: 2rem 1rem;
  
  @include mq(48em) {
    width: 50%;
    padding: 6.5rem 2.5rem 5rem;
    float: right;
  }
  
  @include mq(64.0625em) {
    width: 33.3333%;
  }
}

.story__type {
  position: absolute;
  top: 0;
  left: 1rem;
  padding: 0.25rem 0.5rem;
  background: $blue;
  color: $white;
  font-family: $sans-serif;
  font-size: 0.75rem;
  font-weight: 500;
  @include mq(48em) {
    left: 2.5rem;
  }
  
  + * {
    @include mq(48em) {
      margin-top: 0;
    }
  }
}

//

.story-shift-container {
  &:first-child {
    margin-top: 2rem;
  }
  @include mq(64.0625em) {
    position: relative;
    margin: 0;
    padding: 0;
    overflow: visible;
    
    &:first-child,
    &:last-child {
      margin: 2rem 3rem;
    }
  }
}

.story-shift-viewport {
  margin: -0.375rem;
  padding: 0.375rem;
  // overflow: hidden;
}

.story-shift-nav {
  display: none;
  @include mq(64.0625em) {
    display: block;
    position: absolute;
    top: 50%;
    width: 4.125rem;
    height: 4.125rem;
    border-radius: 50%;
    background-color: $blue;
    transform: translateY(-50%);
    color: $white;
  }
  svg {
    position: absolute;
    left: 20%;
    top: 20%;
    width: 60%;
    height: 60%;
  }
  &:hover {
    background-color: $teal;
  }
}

.story-shift-nav--prev {
  right: calc(100% + 1rem);
}

.story-shift-nav--next {
  left: calc(100% + 1rem);
}

.story-shift-nav--disabled {
  visibility: hidden;
}

.story-shift__item {
  width: 100%;
  margin: 0 0.625rem 0;
  
  @include mq(64.0625em) {
    display: inline-block;
    width: auto;
    margin: 0 1.25rem 0 0;
    font-size: 1.0625rem;
    white-space: normal;
    vertical-align: top;
    word-wrap: break-word;
    &:hover {
      .story {
        box-shadow: 0 19px 12px rgba(0,0,0,0.30), 0 15px 12px rgba(3, 3, 3, 0.22);
      }
    }
  }
  
  .story {
    margin: 0;
    @include mq(64.0625em) {
      height: 100%;
      transition: box-shadow $transition;
    }
  }
}



.story-shift {
  @include mq(64.0625em) {
    transition: transform $transition-easing $transition-speed * 2;
    font-size: 0;
    white-space: nowrap;
    word-wrap: normal;
    
    display: flex;
    .story__media {
      width: 0;
      min-height: 21.25rem;
      transition: width $transition-easing $transition-speed * 2, opacity $transition-easing $transition-speed * 2;
      opacity: 0;
      overflow: hidden;
    }
    
    .story__image {
      width: 31.875rem;
      max-width: none;
    }
    
    .story__text {
      width: 21rem;
      padding: 5rem 2.5rem 5rem;
    }
  }
  
  &::after {
    display: none;
    content: 'flickity';
    @include mq(64.0625em) {
      content: '';
    }
  }
  
  &.flickity-enabled {
    transform: none !important;
    padding-bottom: 3rem;
  }
}

.story-shift__item--active {
  @include mq(64.0625em) {
    .story__media {
      width: 31.875rem;
      opacity: 1;
    }
  }
}