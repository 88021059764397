// clear
@mixin clear {
  &::before,
  &::after {
    display: table;
    width: 0;
    content: '';
  }

  &::after {
    clear: both;
  }
}

// hide
@mixin hide {
  position: absolute;
  left: -999rem;
}

// show
@mixin show {
  position: static;
  left: auto;
}

// zero margins
@mixin zero {
  > :first-child {
    margin-top: 0;
  }

  > :last-child {
    margin-bottom: 0;
  }
}

// focus
@mixin focus {
  outline: 0.125rem solid $focus;
  outline-offset: -0.125rem;
}

// media query
@mixin mq($min: 0, $max: 0) {
  @if ($min > 0 and $max > 0) {
    @media (min-width: $min) and (max-width: $max) {
      @content;
    }
  } @else if ($min > 0) {
    @media (min-width: $min) {
      @content;
    }
  } @else if ($max > 0) {
    @media (max-width: $max) {
      @content;
    }
  }
}

// svg color fill
@mixin fill($svg, $color) {
  $color: str-slice(#{$color}, 2);
  $insert: 'fill%3D%22%23#{$color}%22%20';
  $index: str-index($svg, '%3Csvg%20') + 9;
  background-image: str-insert($svg, $insert, $index);
}

// underline
@mixin underline($color: currentColor, $position: 100%, $size: 0.0625rem) {
  background-image: linear-gradient($color, $color);
  background-repeat: repeat-x;
  background-position: 0 $position;
  background-size: 100% $size;
}

// spacing
@mixin spacing($x: 1) {
  margin-top: 1.25rem * $x;
  margin-bottom: 1.25rem * $x;
}
