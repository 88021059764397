.footer {
  background: $gray-dark;
  color: $white;
  font-family: $sans-serif;
  font-size: 0.875rem;
  font-weight: $book;
  line-height: 1.5;

  p {
    margin: 0;
  }

  a:not([class^=button]) {
    color: inherit;
    font-weight: $bold;
  }
}

.footer__top {
  padding-top: 5rem;
  padding-bottom: 1.5rem;
  border-bottom: 0.0625rem solid $gray-medium;
}

.footer__bottom {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  @include mq(72em) {
    padding-bottom: 5rem;
  }
}

.footer__container {
  @include mq(72em) {
    display: flex;
    align-content: center;
    justify-content: space-between;
  }
}

.footer__nav {
  padding: 0;
  margin: 0;
  list-style: none;
  .footer__top & {
    margin-top: 2rem;
    padding-top: 2rem;
    border-top: 0.125rem solid $gray-medium;
  }
  @include mq(72em) {

    .footer__top & {
      border-top: 0;
      margin-top: 0;
      padding-top: 0;
      width: calc(100% - 13rem);
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
    }
  }
}

.footer__nav__item {
  margin-bottom: 0.5rem !important;
  margin-top: 0.5rem !important;
  @include mq(72em) {
    display: inline-block;
    margin-left: 2rem;
    &:first-child {
      .footer__bottom & {
        margin-left: 0;
      }
    }
  }
}

.footer__nav__link {
  letter-spacing: 1.3px;
  text-transform: uppercase;
}

.footer__social {
  @include clear;
  margin-top: 2rem;
  padding: 0;
  list-style: none;
  @include mq(72em) {
    margin-top: 0.5rem;
  }
}

.footer__social__item {
  margin: 0 0 0 1.25rem;
  float: left;
  &:first-child {
    margin-left: 0;
  }
}

.footer__social__link {
  display: block;
  width: 1.25rem;
  &:hover,
  &:focus {
    color: $gray-middle !important;
  }
}

.footer__text {
  a {
    text-decoration: underline;
  }
}

.footer__logo {
  flex-shrink: 0;
  align-self: center;
  display: block;
  width: 13rem;
  @include mq(72em) {
    border-right: 0.0625rem solid $gray-medium;
    padding-right: 2rem;
  }
}
