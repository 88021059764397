.banner {
  position: relative;
  margin: auto;
}

.banner__image {
  width: 100%;
  min-height: 10rem;
}

.banner__gradient {
  display: none;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 30%;
  background-image: linear-gradient(transparent, $black-fade);
  content: '';

  @include mq(48em) {
    display: block;
  }
}

.banner__text {
  @include zero;
  margin: 0 -1rem;
  padding: 2.5rem 1rem;
  background: $beige;
  p {
    margin-top: 0.75rem;
    margin-bottom: 0.75rem;
    font-size: 0.9375rem;
    line-height: 1.8;
    font-family: $sans-serif;
    font-weight: 500;
  }

  @include mq(48em) {
    display: block;
    position: absolute;
    bottom: 2rem;
    margin: 0;
    padding: 0 2rem 0 0;
    background: none;
    color: $white;
    text-shadow: .1rem .1rem .1rem $black;
  }

  @include mq(64.0625em) {
    max-width: 50%;
  }

  @include mq(90em) {
    padding-right: 3rem;
  }
}
