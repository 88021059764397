.table {
  @include spacing(2);
  position: relative;

  &::after {
    @include fill($svgstore--angle-right, $white);
    position: absolute;
    top: 50%;
    right: 1rem;
    width: 3rem;
    height: 3rem;
    transform: translateY(-50%);
    background-color: $gray-dark;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 2.5rem 2.5rem;
    content: '';
    pointer-events: none;
    @include mq(48em) {
      display: none;
    }
  }
}

.table--scrolled {
  &::after {
    display: none;
  }
}

.table--fixed {
  &::after {
    display: none;
  }
}

.table__scroll {
  overflow: auto;
}

table {
  width: 100%;
  table-layout: fixed;
  border-collapse: separate;
  .table__scroll & {
    min-width: 48rem;

    @include mq(48em) {
      min-width: 0;
    }
  }
}

tr {
  &:nth-child(even) {
    background: $beige;
  }
}

tbody {
  tr {
    &:nth-child(odd) {
      td {
        box-shadow: inset 0.125rem 0 0 $beige;
        &:first-child {
          box-shadow: none;
        }
      }
    }
  }
}

th,
td {
  @include zero;
  padding: 0.75rem 1.25rem;
  box-shadow: inset 0.125rem 0 0 $white;
  vertical-align: top;

  &:first-child {
    border-left: 0;
  }
}

td {
  font-size: 0.875rem;
  a:not([class]) {
    font-weight: 600;
    border-bottom: 1px solid $blue;
    padding-bottom: .125em;
    &:hover {
      border-color: $teal;
      text-decoration: none;
      color: $teal;
    }
  }
}

th {
  background-color: $blue;
  color: $white;
  font-family: $sans-serif;
  font-size: 1.25rem;
  text-align: left;
  font-weight: $semibold;
}
