.notice-group {
  @include spacing(2);
}

.notice {
  @include zero;
  padding: 1.25rem;

  &:nth-child(odd) {
    background: $beige;
  }
}

.notice__label {
  text-transform: uppercase;
  font-weight: 600;
}
