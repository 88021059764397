.menu-container {
  position: relative;
  max-width: 90rem;
  margin: auto;
}

.menu {
  @include hide;
  @include zero;
  width: 100%;
  padding: 2.5rem 1rem;
  background: $beige;
  font-family: $sans-serif;
  font-size: 1rem;
  line-height: 1.125;
  box-shadow: 0 0 0.375rem $black-shadow;
  visibility: hidden;
  z-index: 4;

  @include mq(48em) {
    padding-right: 2rem;
    padding-left: 2rem;
  }

  @include mq(64.0625em) {
    width: 21.25rem;
    padding-right: 1rem;
    padding-left: 1rem;
  }

  .menu-active & {
    @include show;
    position: absolute;
    visibility: visible;

    @include mq(64.0625em) {
      right: 2rem;
    }

    @include mq(90em) {
      right: 3rem;
    }
  }
}

.menu--small {
  @include mq(64.0625em) {
    display: none;
  }
}

.menu--large {
  display: none;
  @include mq(64.0625em) {
    display: block;
  }
}

.menu__list {
  @include spacing(2);
  padding: 0;
  list-style: none;
}

.menu__list--primary {
  text-transform: uppercase;
}

.menu__list--sub {
  @include hide;
  margin: 1.25rem 0 0;
  font-weight: 500;
  text-transform: none;
  visibility: hidden;
  .menu__item--active > & {
    @include show;
    visibility: visible;
  }
}

.menu__item {
  @include spacing;
  .button {
    &:hover {
      background-color: $blue;
      color: $white;
      border-color: $blue;
    }
  }
}

.menu__item--sub {

}

.menu__link {
  display: inline-block;
  max-width: calc(100% - 2rem);
  font-weight: $semibold;
  // color: inherit;
  .menu__item--current > & {
    // color: inherit;
    font-weight: $semibold;
    &:hover {
      text-decoration: none;
    }
  }
}

.menu__link--null {
  color: $blue;
}

.menu__link--sub {
  padding-left: 0.75rem;
  font-weight: 400;
}

.menu__link--sub-sub {
  padding-left: 1.5rem;
  font-weight: 400;
}

.menu__sub-toggle {
  width: 1.125rem;
  float: right;
  // color: inherit;
}

// .menu__sub-toggle__icon {
//   .menu__item--active & {
//     transform: rotate(180deg);
//   }
// }

.menu__sub-toggle__icon--inactive {
  display: block;

  .menu__item--active > .menu__sub-toggle > span > & {
    display: none;
  }
}

.menu__sub-toggle__icon--active {
  display: none;

  .menu__item--active > .menu__sub-toggle > span > & {
    display: block;
  }
}