.grid {
  @include spacing(2);
}

.grid__container {
  @include clear; // ie9
  display: flex;
  flex-wrap: wrap;
  margin: -0.625rem;
}

.grid__container--scroll {
  &::before {
    @include fill($svgstore--angle-right, $white);
    position: absolute;
    top: 50%;
    right: 1rem;
    width: 3rem;
    height: 3rem;
    transform: translateY(-50%);
    background-color: $gray-dark;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 2.5rem 2.5rem;
    content: '';
    z-index: 1;
    pointer-events: none;
    @include mq(48em) {
      display: none;
    }
  }

  &::after {
    display: none;
    content: 'flickity';

    @include mq(80em) {
      content: '';
    }
  }

  &.flickity-enabled {
    display: block;
  }

  .flickity-slider > * {
    width: 19.25rem;

    @include mq(48em) {
      width: 22.625rem;
    }
  }
}

.grid__container--slider {
  &::after {
    display: none;
    content: 'flickity';

    @include mq(64.0625em) {
      content: '';
    }
  }

  &.flickity-enabled {
    display: block;
    padding-bottom: 3rem;
  }

  .flickity-viewport {
    position: relative;
    overflow: hidden;
  }
  .flickity-slider {
    display: flex;
    position: static;
    > * {
      width: 100%;
      flex-shrink: 0;
      position: relative !important;
      left: 0 !important;
      float: none;
    }
  }
}

.grid__container--scroll--scrolled {
  &::before {
    display: none;
  }
}

.grid__container--scroll--alt {
  .flickity-slider > * {
    top: 50%;
    transform: translateY(-50%);
    @include mq(64em) {
      width: 30.625rem;
    }
  }
}

.grid__container--space {
  margin: -1.25rem -0.625rem;
}

.grid__container--space-alt {
  margin: -0.625rem -2rem;
}

.grid__item {
  @include zero;
  width: 100%;
  padding: 0.625rem;
  float: left; // ie9
}

.grid__item--space {
  padding: 1.25rem 0.625rem;
}

.grid__item--space-alt {
  padding: 0.625rem 2rem;
}

.grid__item--flex {
  display: flex;

  > * {
    width: 100%;
  }
}

.grid__item--center {
  align-self: center;
}

.grid__item--4 {
  @include mq(32em) {
    width: 50%;
  }
  @include mq(64.0625em) {
    width: 25%;
  }
}

.grid__item--3 {
  @include mq(32em) {
    width: 50%;
  }
  @include mq(64.0625em) {
    width: 33.3333%;
  }
}

.grid__item--3--narrow {
  @include mq(64.0625em) {
    width: 50%;
  }
  @include mq(90em) {
    width: 33.3333%;
  }
}

.grid__item--3--wide {
  @include mq(32em) {
    width: 100%;
  }
  @include mq(48em) {
    width: 50%;
  }
  @include mq(80em) {
    width: 33.3333%;
  }
}

.grid__item--60 {
  @include mq(48em) {
    width: 60%;
  }
}

.grid__item--40 {
  @include mq(48em) {
    width: 40%;
  }
}


.grid__item--2 {
  @include mq(32em) {
    width: 50%;
  }
}
