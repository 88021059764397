.wrap {
  @include clear;
  max-width: 90rem;
  margin-right: auto;
  margin-left: auto;
  padding-right: 1rem;
  padding-left: 1rem;

  @include mq(48em) {
    padding-right: 2rem;
    padding-left: 2rem;
  }

  @include mq(64.0625em) {
    padding-right: 3rem;
    padding-left: 3rem;
  }
}

.wrap--wide {
  max-width: 96rem;
}

.wrap--narrow {
  max-width: 70rem;
}

.wrap--slim {
  max-width: 58rem;
}

.wrap--relative {
  position: relative;
}

.main,
.sidebar {
  @include zero;
  @include spacing(2.6);
}

.main {
  @include spacing(2);
  @include mq(64.0625em) {
    @include spacing(2.6);
    width: calc(100% - 22rem);
    float: right;
  }

  @include mq(64.0625em) {
    width: calc(100% - 24rem);
  }
}

.main--center {
  @include mq(64.0625em) {
    margin-right: auto;
    margin-left: auto;
    float: none;
  }
}

.sidebar {
  font-size: 0.9375rem;
  @include mq(64.0625em) {
    width: 18rem;
    float: left;
  }
}

.main + .sidebar + .main {
  @include mq(64.0625em) {
    margin-top: 0;
  }
}
