.widget {
  @include zero;
  @include spacing(4);
  img {
    margin-left: auto;
    margin-right: auto;
  }
  p {
    margin-top: 1rem;
    margin-bottom: 1rem;
    a:not([class]) {
      font-weight: $semibold;
      border-bottom: 1px solid $blue;
      padding-bottom: .125em;
      &:hover {
        border-color: $teal;
        text-decoration: none;
        color: $teal;
      }
    }
  }
}

.widget--large {
  font-size: 1.25rem;
  line-height: 1.4;
}

.widget--small {
  @include spacing(2);
}

.widget__heading {
  margin-top: 2.5rem;
  margin-bottom: 0.75rem;
  padding-top: 0.75rem;
  border-top: 0.25rem solid $gray-dark;
  font-family: $sans-serif;
  font-size: 0.9375rem;
  font-weight: $semibold;


  a {
    font-weight: $semibold;
  }

  .main & {
    font-size: 1.75rem;
    font-weight: $book;
    line-height: 1.142;
    padding-top: 1.25rem;
  }
}

.widget__heading--small {
  padding-top: 0;
  border-top: 0;
}

.widget__social {
  @include clear;
  @include spacing;
  padding: 0;
  list-style: none;
}

.widget__social__item {
  margin: 0 1.25rem 0 0;
  float: left;
}

.widget__social__link {
  display: block;
  width: 1.4rem;
  color: $blue;
  &:hover,
  &:focus {
    color: $teal;
  }
}
