.subnav {
  @include spacing(2);
  display: none;
  padding: 1rem 0 0;
  border-top: .25rem solid $gray-dark;
  font-family: $sans-serif;
  font-size: 1rem;
  line-height: 1.25;
  list-style: none;
  font-weight: 600;
  @include mq(64.0625em) {
    display: block;
  }
}

.subnav--sub {
  position: relative;
  margin: 1rem 0 0;
  padding: 0;
  border-top: 0;
  font-weight: 400;

  &::before {
    position: absolute;
    width: 0.25rem;
    height: 100%;
    background: $gray-light;
    content: '';
  }
}

.subnav__item {
  margin: 1rem 0 0;
}

.subnav__link {
  display: inline-block;
  position: relative;
  font-weight: 600;
  .subnav__item--current > & {
    color: $black;
    font-weight: 600;

    &:hover {
      text-decoration: none;
    }
  }
}

.subnav__link--sub,
.subnav__link--sub-sub {
  padding-left: 1rem;
  font-weight: 400;
  .subnav__item--current > & {
    &::before {
      position: absolute;
      left: 0;
      width: 0.25rem;
      height: 100%;
      background: $gray;
      content: '';
    }
  }
  .subnav__item--current-alt > & {
    &::before {
      position: absolute;
      left: 0;
      width: 0.25rem;
      height: 100%;
      background: $gray;
      content: '';
    }
  }
}

.subnav__link--sub-sub {
  padding-left: 1.75rem;
}

.subnav__sub-toggle {
  width: 1.125rem;
  float: right;
}


.subnav__sub-toggle__icon--inactive {
  display: block;
  .subnav__item--active > .subnav__sub-toggle > span > & {
    display: none;
  }
}


.subnav__sub-toggle__icon--active {
  display: none;
  .subnav__item--active > .subnav__sub-toggle > span > & {
    display: block;
  }
}
