.dropdown {
  @include spacing;
  position: relative;
  width: 20rem;
  max-width: 100%;
}

.dropdown--full {
  width: 100%;
}

.dropdown__toggle {
  position: relative;
  display: block;
  padding-right: 3rem;
  padding-left: 0.75rem;
  text-transform: none;
  font-size: 0.9375rem;
  background-color: $white;
  border-color: $blue;
  color: $blue;
  .toggle__form__dropdown & {
    background-color: transparent;
  }
  &:hover,
  &:focus {
    background-color: $blue;
    border-color: $blue;
    color: $white;
  }
  .dropdown--active & {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border-bottom-color: transparent;
    border-top-color: $black;
    background: $beige;
    color: $blue;
  }

  .toggle__form__dropdown .dropdown--active & {
    background-color: $blue;
    color: $white;
  }
}

.dropdown__icon {
  position: absolute;
  top: 50%;
  right: 1rem;
  transform: translateY(-50%);
  width: 1.25rem;
  .dropdown--active & {
    transform: rotate(180deg) translateY(50%);
  }
}

.dropdown__list {
  @include hide;
  width: 100%;
  margin-top: -0.0625rem;
  margin-bottom: 0;
  padding: 0 0.75rem 0.75rem;
  border: 0.0625rem solid $black;
  border-top: 0;
  border-bottom-left-radius: 0.1875rem;
  border-bottom-right-radius: 0.1875rem;
  background: $beige;
  list-style: none;
  box-shadow: 0 0.375rem 0.375rem $black-shadow;
  visibility: hidden;
  z-index: 1;

  .dropdown--active & {
    @include show;
    position: absolute;
    visibility: visible;
  }

  .toggle__form__dropdown .dropdown--active & {
    background-color: $blue;
    .button--text {
      color: $white;
    }
  }
}

.dropdown__item {
  margin: 0;
  // margin-bottom: 0.625rem;
}

.dropdown__link {
  display: block;
  font-size: 0.9375rem;
  text-transform: none;
  padding: 0.65rem 0;
}
