.overlay {
  position: relative;

}

.overlay--image {
  background-size: cover;
  overflow: hidden;
  background-position: center;
  &::before {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba($black, .33);
  }
}

.overlay--video {
  width: 100%;
  height: auto;
  overflow: hidden;
  @include mq(48em) {
    height: 600px;
  }
}

.overlay__image, .overlay__video, .overlay__video__media {
  width: 100%;
}

.overlay__image {
  position: static;
}

.overlay__video {
  width: 100%;
  max-height: 768px;
}

.overlay__wrap {
  @include zero;
}

.overlay__container {
  @include zero;
  margin: 0 -1rem;
  padding: 2.5rem 1rem;
  background: $beige;

  .overlay--image & {
    position: relative;
    transform: none;
    background: transparent;
    width: 100%;
    max-width: 100%;
    margin: 0;
    padding: 0;
  }
  @include mq(48em) {
    position: absolute;
    top: 50%;
    width: 100%;
    max-width: calc(100% - 4rem);
    margin: 0;
    padding: 0;
    transform: translateY(-50%);
    background: transparent;
    color: $white;
  }

  @include mq(90em) {
    width: 84rem;
    max-width: calc(100% - 6rem);
  }
}

.overlay__title {
  color: $white;
  text-shadow: .1rem .1rem .1rem $black;
}

.overlay__text {
  font-family: $sans-serif;
  font-size: 1.25rem;

  @include mq(48em) {
    font-size: 1.75rem;
    text-shadow: .1rem .1rem .1rem $black;
  }
}

.overlay__button {
  @include mq(48em) {
    border-color: $white;
    background: $white;
  }
}
