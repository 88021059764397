.accordion-group {
  @include zero;
  @include spacing(2);
  .tab-accordions & {
    @include mq(64.0625em) {
      display: none;
    }
  }
}

.accordion {
  position: relative;
  overflow: hidden;
  @include spacing;
  .tab-accordions & {
    margin: 0.125rem 0;
  }
}

.accordion__heading__toggle {
  font-size: 1.75rem;
  margin: 0;
  line-height: 1.7059;
}

.accordion__toggle,
.accordion__content {
  padding: 1.25rem;
  background: $beige;
}

.accordion__toggle {
  display: block;
  color: $blue;
  font-family: $sans-serif;
  font-weight: $book;
  .tab-accordions & {
    position: relative;
    border-radius: 0.1875rem;
    background-color: $blue;
    font-size: 0.875rem;
    font-weight: $semibold;
    text-transform: uppercase;
    color: $white;
  }
  &:hover {
    text-decoration: none;
    .accordion__icon {
      background-color: $teal;
    }
  }
}

.accordion__icon {
  border-radius: 50%;
  background-color: $blue;
  width: 2.875rem;
  margin-left: 1.25rem;
  padding: 0.75rem;
  float: right;
  color: $white;
  pointer-events: none;
}

.accordion__icon--alt {
  display: block;
  position: absolute;
  top: 50%;
  right: 1rem;
  transform: translateY(-50%);
  width: 1.25rem;
  height: 1.25rem;
  color: $white;
  .accordion--active & {
    color: $white;
    transform: rotate(180deg) translateY(50%);
  }
}

.accordion__icon--active {
  display: none;
  
  .accordion--active & {
    display: block;
  }
}
.accordion__icon--inactive {
  display: block;
  
  .accordion--active & {
    display: none;
  }
}

.accordion__content {
  @include hide;
  @include zero;
  top: 0;
  margin-top: 0.125rem;
  visibility: hidden;
  @extend .p--small;
  
  .accordion--active & {
    @include show;
    visibility: visible;
  }
  
  a:not([class]) {
    border-bottom: 1px solid $blue;
    padding-bottom: .125em;
    &:hover {
      border-color: $teal;
      text-decoration: none;
      color: $teal;
    }
  }
  
  .tab-accordions & {
    background: transparent;
    padding: 1rem 0;
    margin: 0;
  }
}

.accordion__heading {
  margin-top: 2.5rem;
  margin-bottom: 1.25rem;
  font-size: 1.125rem;
  font-weight: 500;
  line-height: 1.3333;
}

.accordion__expand {
  text-align: right;
  display: block;
  font-family: $sans-serif;
  font-size: 1.25rem;
  font-weight: $semibold;
}
