// .flickity-enabled:focus {
//   outline: 0.125rem solid $focus;
//   outline-offset:0;
// }

.flickity-page-dots {
  bottom: 0.5rem;
  width: 100%;
  font-size: 0;

  .dot {
    width: 1rem;
    height: 1rem;
    margin: 0 0.25rem;
    border: 0.0625rem solid;
    background: $transparent;
    color: $blue;
    opacity: 1;
    .overlay--image & {
      color: $white;
    }
    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }

    &.is-selected {
      background: currentColor;
    }
  }
}

.flickity-prev-next-button {
  width: 3.5rem;
  height: 3.5rem;
  padding: 0.5rem;
  border-radius: 0;
  background: $transparent;

  .flickity-button-icon {
    position: static;
    width: auto;
    height: auto;
  }


  &:active {
    opacity: 1;
  }

  &:hover {
    background: $teal;
  }

  &:disabled {
    opacity: 0;
  }

  &.previous {
    left: 0;
  }

  &.next {
    right: 0;
  }

  .arrow {
    fill: $white-opacity;
  }
}
