.tab-accordions {
  .tabs {
    display: none;
    @include mq(64.0625em) {
      display: block;
    }
  }
  .accordion {
    display: block;
    @include mq(64.0625em) {
      display: none;
    }
  }
}

.tabs {
  margin: 3rem 0;
}

[role="tablist"] {
  margin: 0 0 2rem;
  overflow: visible;
  display: flex;
  justify-content: space-between;
}

[role="tab"] {
  position: relative;
  margin: 0;
  padding: 0.75rem 0.5rem;
  font-family: $sans-serif;
  border: 0.0625rem solid $blue;
  background-color: $white;
  border-radius: 0.1875rem;
  font-size: 0.875rem;
  line-height: 1;
  font-weight: $semibold;
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 0;
  margin-left: 1rem;
  flex-grow: 1;
  &:first-child {
    margin-left: 0;
  }
}

[role="tab"][aria-selected="true"]::before {
  @include mq(64.0625em) {
    border-width: 0.5625rem 0.5625rem 0 0.5625rem;
    border-color: $blue transparent transparent transparent;
    position: absolute;
    bottom: -0.5625rem;
    left: 50%;
    transform: translateX(-50%);
    display: block;
    
    content: '';
  }
}



[role="tab"][aria-selected="true"] {
  background-color: $blue;
  color: $white;
}

[role="tab"][aria-selected="true"]:not(:focus):not(:hover)::before {
  // border-top: 5px solid hsl(218, 96%, 48%);
}

[role="tab"]:hover,
[role="tab"]:focus,
[role="tab"]:active {
  background-color: $blue;
  color: $white;
  [data-whatinput='keyboard'] & {
    @include focus;
    outline-offset: 0.125rem;
  }
}

[role="tabpanel"] {
  position: relative;
  z-index: 2;
  @include mq(64.0625em) {
    border: 0.0625rem solid $gray-medium;
  }
}

[role="tabpanel"]:focus {
  [data-whatinput='keyboard'] & {
    @include focus;
    outline-offset: 0.125rem;
  }
}

.tab__content {
  @include mq(64.0625em) {
    display: flex;
  }
}

.tab__content__image {
  @include mq(64.0625em) {
    width: 60%;
    flex-shrink: 0;
  }
}

.tab__content__text {
  @include zero;
  padding: 1rem;
  background-color: $white;
  
  @include mq(64.0625em) {  
    padding-left: 3rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
  }
}