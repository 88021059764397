.toggle__form {
  legend {
    font-family: $sans-serif;
    font-size: 0.9375rem;
    font-weight: $bold;
    margin-bottom: 1rem;
  }

  .form__radio {
    display: inline-block;
    margin-right: 1rem;
    margin-bottom: 1rem;
  }

  label {
    font-family: $sans-serif;
    font-size: 0.8125rem;
    font-weight: $bold;
    color: $blue;
    text-transform: uppercase;
  }
}

.toggle__form__dropdown {
  max-width: 400px;
}

.toggle__form__dropdown[data-toggle] {
  display: none;
  &.active {
    display: block;
  }
}