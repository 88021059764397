.form {
  @include zero;
  font-family: $sans-serif;
  font-size: 1rem;
  font-weight: 500;
}

.form__group {

}

.form__group--narrow {
  max-width: 40.625rem;
}

.form__input {
  display: block;
  width: 100%;
  height: 3rem;
  margin-top: 0.5rem;
  padding: 0.75rem;
  border: 0.0625rem solid $black-light;
  background: $white;
  line-height: normal;
  
  box-shadow: inset 0 1px 3px rgba(0,0,0,.3);
  border-radius: 0.1875rem;
  &::placeholder {
    color: inherit;
  }
  
  &:focus {
    box-shadow: none;
  }
}

.form__textarea {
  @extend .form__input;
  height: auto;
  min-height: 5rem;
}

.form__req {
  color: #FF0200;
}

.form__label {
  @extend .p--large;
}

.form__select {
  position: relative;
  margin-top: 0.5rem;
  box-shadow: inset 0 1px 3px 0 rgba(0,0,0,0.50);
  border-radius: 0.1875rem;
  select {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
    opacity: 0;
  }
  
  option {
    font-weight: $semibold;
  }
}

.form__control {
  font-family: $sans-serif;
  font-weight: $semibold;
  display: block;
  width: 100%;
  height: 3rem;
  margin-top: 0.5rem;
  padding-left: 0.75rem;
  padding-right: 3rem;
  border: 0.0625rem solid $blue;
  color: $blue;

  line-height: normal;
  font-size: 0.9375rem;
  border-radius: 0.1875rem;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;

  @include fill($svgstore--chevron-down-alt, $blue);
  background-color: $white;
  background-repeat: no-repeat;
  background-position: calc(100% - 1rem) 50%;
  background-size: 1.25rem 1.25rem;
  appearance: none;
}

.form__select__text {
  width: 100%;
  height: 3rem;
  padding: 1rem 3rem 1rem 0.75rem;
  border: 0.0625rem solid;
  border-radius: 0.1875rem;
  color: $black;
  font-family: $sans-serif;
  line-height: 1;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  word-wrap: normal;
}

.form__select__text--focus {
  @include focus;
}

.form__select__arrow {
  position: absolute;
  top: 0;
  right: 0;
  width: 3rem;
  height: 3rem;
  padding: 1rem;
}

$focusGray: $black-light;
$focusBlue: $blue;
$radio-size: 1.25rem;
.form__radio {
  input[type="radio"] {
    position: absolute;
    border: 0;
    margin: -1px;
    padding: 0;
    width: 1px;
    height: 1px;
    overflow: hidden;
    clip: rect(0px, 0px, 0px, 0px);
  }
  
  label {
    position: relative;
    display: inline-block;
    margin-left: 2px;
    padding-left: calc(#{$radio-size} + 10px);
    height: $radio-size;
  }
  
  input[type="radio"] + label:before {
    position: absolute;
    top: 0;
    left: 0;
    width: $radio-size;
    height: $radio-size;
    overflow: hidden;
    margin: 0;
    padding: 0;
  }
  
  
  
  input[type="radio"] + label:before {
    content: '\a0';
    position: absolute;
    left: 0;
    top: 50%;
    margin-top: -0.5rem;
    height: $radio-size;
    width: $radio-size;
    border: 1px solid $blue;
    border-radius: 50%;
    background: $white;
    .module--beige & {
      background: $beige;
    }
    .module--gray-light & {
      background: $gray-light;
    }
  }
  
  input[type="radio"]:checked + label::before {
    background: $focusBlue;
    background-size: 150%;
    box-shadow: inset 0 0 0 .125rem $white;
    .module--beige & {
      box-shadow: inset 0 0 0 .125rem $beige;
    }
  }
  
  input[type="radio"]:focus + label::before {
    [data-whatinput='keyboard'] & {
      @include focus;
    }
  }
  
}


.form__checkbox {
  margin: 0.5rem 0;
  label {
    position: relative;
    display: inline-block;
    margin-left: 2px;
    padding-left: calc(#{$radio-size} + 10px);
    // height: $radio-size;
  }
  
  input[type="checkbox"] {
    position: absolute;
    border: 0;
    margin: -1px;
    padding: 0;
    width: 1px;
    height: 1px;
    overflow: hidden;
    clip: rect(0px, 0px, 0px, 0px);
  }
  input[type="checkbox"] + label:before,
  input[type="checkbox"] + label:after {
    position: absolute;
    top: 0;
    left: 0;
    width: $radio-size;
    height: $radio-size;
    overflow: hidden;
    margin: 0;
    padding: 0;
  }
  
  input[type="checkbox"] + label:before {
    content: '\a0'; // simulates a non-breaking space: http://stackoverflow.com/questions/190396/adding-html-entities-using-css-content
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    // margin-top: -0.25rem; //half height and border to vertically align
    height: $radio-size;
    width: $radio-size;
    border: 1px solid $focusGray;
    box-shadow: inset 0 1px 3px rgba(0,0,0,.3);
    background-color: $white;
    .module--beige & {
      background-color: $beige;
    }
  }
  
  input[type="checkbox"]:checked + label::before {
    background: $focusBlue;
    border-color: $focusBlue;
  }

  input[type="checkbox"]:checked + label::after {
    position: absolute;
    content: "";
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 20px;
    height: 20px;
    @include fill($svgstore--checkmark, $white);
    background-size: 15px 13px;
    background-position: center center;
    background-repeat: no-repeat;
  }
  
  input[type="checkbox"]:focus + label::before {
    [data-whatinput='keyboard'] & {
      @include focus;
    }
  }
}
