.button-group {
  @include zero;
  @include spacing(2);
}

.button-stack {
  margin: 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  margin-left: -1rem;
  margin-right: -1rem;
}

.button-stack__item {
  display: flex;
  flex-grow: 1;
  margin-bottom: 2rem !important;
  margin-left: 1rem;
  margin-right: 1rem;
  .button-stack--3 & {
    @include mq(32em) {
      flex-grow: 0;
      width: calc(33% - 2rem);
    }
  }
  
  .button-stack--2 & {
    @include mq(32em) {
      flex-grow: 0;
      width: calc(50% - 2rem);
    }
  }
  
  .button {
    flex-grow: 1;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.button {
  display: inline-block;
  padding: 0.85rem 1.875rem;
  border: 0.0625rem solid transparent;
  border-radius: 0.1875rem;
  color: $blue;
  font-family: $sans-serif;
  font-size: 0.875rem;
  font-weight: $semibold;
  line-height: 1.1667;
  text-transform: uppercase;
  background-color: $white;
  border-color: $blue;
  &:hover,
  &:focus {
    background-color: $teal;
    border-color: $teal;
    color: $white;
    text-decoration: none;
    [data-whatinput='keyboard'] & {
      outline-offset: 0.125rem;
    }
  }
}

.button--secondary {
  border-color: $blue;
  color: $white;
  background-color: $blue;
  &:hover,
  &:focus {
    background-color: $teal;
    border-color: $teal;
    color: $white;
  }
}

.button--teal {
  border-color: $teal;
  background: $teal;
  color: $white;
  &:hover,
  &:focus {
    background-color: $white;
    color: $blue;
    border-color: $white;
  }
}

.button--text {
  padding: 0;
  border: 0;
  color: $blue;
  background-color: transparent;
  &:hover,
  &:focus {
    color: $blue;
    text-decoration: underline;
    background-color: transparent;
  }
}

.button--full {
  @include spacing;
  display: block;
  width: 100%;
  text-align: center;
}

.button--short {
  padding: 0.5rem 1.5rem;
  text-transform: none;
}

.button--link {
  font-family: $sans-serif;
  font-weight: $semibold;
  font-size: 0.8125rem;
  text-transform: uppercase;
  color: $blue;
  border-bottom: 1px solid $blue;
  padding-bottom: .125em;
  line-height: .9;
  &:hover,
  &:focus {
    border-color: $teal;
    color: $teal;
    text-decoration: none;
  }
}