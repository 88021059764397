.media {
  @include zero;
  @include spacing(2);
}

.media--narrow {
  @include mq(48em) {
    max-width: 66.6667%;
  }
}

.media--left,
.media--right {
  @include mq(48em) {
    width: calc(50% - 1rem);
  }
}

.media--left {
  @include mq(48em) {
    margin-right: 2rem;
    float: left;
  }
}

.media--right {
  @include mq(48em) {
    margin-left: 2rem;
    float: right;
  }
}

.media--tight {
  @include spacing(-2);
  @include mq(64.0625em) {
    @include spacing(2);
  }
}

.media__text {
  @include zero;
  margin: 1rem 0;
}

.media__text--beige {
  background: $beige;
  margin: 0;
  padding: 1.25rem;
}

.media__item {
  display: inline-block;
  position: relative;
  &:focus {
    [data-whatinput='keyboard'] & {
      outline-offset: 0;
    }
  }
  .media--full & {
    display: block;
  }
  img {
    width: 100%;
  }
}

.media__overlay {
  @include zero;
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  padding: 0 1.25rem;
  transform: translateY(-50%);
}

.media__overlay--alt {
  @include zero;
  position: absolute;
  bottom: 1.25rem;
  left: 1.25rem;
}

.media__overlay__text {
  color: $white;
  font-family: $sans-serif;
  font-weight: 600;
  text-align: center;
  text-shadow: .1rem .1rem .1rem $black;
}

.media__icon {
  position: absolute;
  bottom: 1.25rem;
  left: 1.25rem;
  width: 4.25rem;
  
  .image-slider & {
    display: block;
    position: static;
    margin: 2rem auto auto auto;
    color: $black-light;
  }
}

.media__credit {
  margin-top: -0.75rem;
}

.media__embed {
  position: relative;
  padding-bottom: 56.25%;
  > * {
    position: absolute;
    width: 100%;
    height: 100%;
    
  }
  
}
.jw-skin-bekle.jwplayer:not(.jw-flag-touch):not(.jw-error):not(.jw-state-error):not(.jw-state-buffering) .jw-display-icon-container:focus {
  background-color: #139ed5 !important;
}
.jw-skin-bekle:not(.jw-flag-touch) .jw-button-color:hover, .jw-skin-bekle .jw-button-color:focus {
  background-color: #139ed5 !important;
}



.media__container__link {
  display: block;
  &:focus {
    [data-whatinput='keyboard'] & {
      outline-offset: 0;
    }
  }

  .media__item {
    overflow: hidden;
    img {
      transition: transform $transition;
    }
  }

  &:hover {
    color: $blue;
    .h3 span,
    .h4 span,
    .h5 span {
      color: $blue;
    }

    img {
      transform: scale(1.1);
    }
  }
  
  .h3 span,
  .h4 span,
  .h5 span,
  .h6 span {
    color: $blue;
  }
}
