// transitions
$transition-speed: 300ms;
$transition-easing: ease;
$transition: $transition-speed $transition-easing;

// fonts
$serif: 'merriweather', serif;
$sans-serif: 'Whitney SSm A', 'Whitney SSm B', sans-serif;

$light: 300;
$book: 400;
$medium: 500;
$semibold: 600;
$bold: 700;

// colors
$blue: #003478;
$blue-opacity: rgba($blue, 0.5);
$teal: #0B7886;
$purple: #8c2287;
$yellow: #fffb00;
$beige: #f5f4f0;
$white: #fff;
$white-opacity: rgba($white, 0.75);
$gray: #bcbcbc;
$gray-light: #f5f5f5;
$gray-middle: #DBDBDB;
$gray-medium: #797a7c;
$gray-dark: #57595B;
$black: #000;
$black-light: #2f2f2f;
$black-shadow: rgba($black, 0.10);
$black-fade: rgba($black, 0.75);
$transparent: rgba($white, 0.001);
$focus: rgba(77, 144, 254, 1);


// animations

@keyframes fadeUp {
  0% {
    opacity: 0;
    transform: translateY(60px);
  }

  100% {
    opacity: 1;
    transform: none;
  }
}