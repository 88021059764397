.align-left,
.align-right {
  margin-bottom: 1.25rem;

  @include mq(48em) {
    max-width: 40%;
  }
}

.align-left {
  @include mq(48em) {
    margin-right: 1.25rem;
    float: left;
  }
}

.align-right {
  @include mq(48em) {
    margin-left: 1.25rem;
    float: right;
  }
}

.align-small {
  @include mq(48em) {
    max-width: 25%;
  }
}

.align-zero {
  @include mq(48em) {
    margin-bottom: 0;
  }
}

.full-width {
  @include spacing(2);
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.semibold {
  font-weight: $semibold;
}

.hide {
  @include hide;
}

.triangle-tab {
  position: relative;

  &::before {
    position: absolute;
    top: 0;
    left: 50%;
    margin-left: -1.25rem;
    border-top: 1.25rem solid $blue;
    border-right: 1.25rem solid transparent;
    border-left: 1.25rem solid transparent;
    content: '';
  }
}

.indent {
  @include zero;
  margin-top: 3rem;
  margin-bottom: 3rem;
  padding-left: 1.25rem;
  border-left: 0.5rem solid $blue;
}

.skip {
  @include hide;

  &:focus {
    position: fixed;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
    z-index: 5;
  }
}


.skip-carousel  {
  @include hide;

  &:focus {
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    z-index: 5;
    text-align: center;
  }
}

.shield-icon {
  margin: 2rem auto;
  width: 6.25rem;
  height: 6.25rem;
}

.only-mobile {
  display: block;
  @include mq(64.0625em) {
    display: none;
  }
}

.only-desktop {
  display: none;
  @include mq(64.0625em) {
    display: block;
  }
}

.clearfix {
  @include clear;
}

.fadeUp {
  opacity: 0;
}

.fadeUp--active {
  animation: fadeUp 800ms cubic-bezier(.645, .045, .355, 1) forwards;
}

.zoomin {
  overflow: hidden;
  img {
    transition: transform $transition;
  }
  &:hover {
    img {
      transform: scale(1.1);
    }
  }
}