.module {
  @include zero;
  padding-top: 5rem;
  padding-bottom: 5rem;
  
  &.triangle-tab {
    padding-top: 6.25rem;
  }
}

.module--tight {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
  
  @include mq(64.0625em) {
    padding-top: 5rem;
    padding-bottom: 5rem;
  }
  
  &.triangle-tab {
    padding-top: 3.75rem;
    
    @include mq(64.0625em) {
      padding-top: 6.25rem;
    }
  }
}

.module--tight-alt {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
  
  @include mq(64.0625em) {
    padding-top: 3.25rem;
    padding-bottom: 3.25rem;
  }
}

.module--margin {
  margin-top: 5rem;
  margin-bottom: 5rem;
}

.module--beige {
  background: $beige;
}

.module--gray-light {
  background: $gray-light;
}

.module--gray-dark {
  background: $gray-dark;
  color: $white;
  
  .module__title {
    color: $white;
  }
}

.module--blue {
  background: $blue;
  color: $white;
  
  .module__title {
    color: $white;
  }
}

.module--shadow {
  position: relative;
  box-shadow: 0 0 0.375rem $black-shadow;
  z-index: 1;
}

.module--relative {
  position: relative;
}

.module__container {
  @include zero;
  
  p:nth-of-type(3) {
    &.p--sans-serif {
      margin-top: -1rem;
    }
    
  }
}

.module__container__link {
  display: block;
  &:focus {
    outline-offset: 0;
  }
}

.module__title {
  margin-bottom: 2.5rem;
}

.module__title--alt {
}

.module__subtitle {
  margin-top: -2rem;
  margin-bottom: 2.5rem;
  font-size: 1.25rem;
  text-align: center;
}

.module--overflow-hide {
  overflow: hidden;
}