.alert {
  padding: 2.5rem 0;
  background: $black-light;
  color: $white;
}

.alert__container {
  position: relative;
  padding: 0 2.5rem;
}

.alert__icon {
  position: absolute;
  top: 0.375rem;
  left: 0;
  width: 1.5rem;
  color: $yellow;
}

.alert__text {
  font-family: $sans-serif;
  font-size: 1.375rem;
  font-weight: 500;
  a {
    color: $white;
    text-decoration: underline;
  }
}

.alert__label {
  color: $yellow;
  text-transform: uppercase;
}

.alert__close {
  position: absolute;
  top: 0.5rem;
  right: 0;
  width: 1.25rem;
  color: $white;
}
