/*! Flickity v1.2.1
http://flickity.metafizzy.co
---------------------------------------------- */
.flickity-enabled {
  position: relative; }

.flickity-enabled:focus {
  outline: none; }

.flickity-viewport {
  overflow: hidden;
  position: relative;
  height: 100%; }

.flickity-slider {
  position: absolute;
  width: 100%;
  height: 100%; }

/* draggable */
.flickity-enabled.is-draggable {
  -webkit-tap-highlight-color: transparent;
  tap-highlight-color: transparent;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.flickity-enabled.is-draggable .flickity-viewport {
  cursor: move;
  cursor: -webkit-grab;
  cursor: grab; }

.flickity-enabled.is-draggable .flickity-viewport.is-pointer-down {
  cursor: -webkit-grabbing;
  cursor: grabbing; }

/* ---- previous/next buttons ---- */
.flickity-prev-next-button {
  position: absolute;
  top: 50%;
  width: 44px;
  height: 44px;
  border: none;
  border-radius: 50%;
  background: white;
  background: rgba(255, 255, 255, 0.75);
  cursor: pointer;
  /* vertically center */
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%); }

.flickity-prev-next-button:hover {
  background: white; }

.flickity-prev-next-button:focus {
  outline: none;
  box-shadow: 0 0 0 5px #09F; }

.flickity-prev-next-button:active {
  filter: alpha(opacity=60);
  /* IE8 */
  opacity: 0.6; }

.flickity-prev-next-button.previous {
  left: 10px; }

.flickity-prev-next-button.next {
  right: 10px; }

/* right to left */
.flickity-rtl .flickity-prev-next-button.previous {
  left: auto;
  right: 10px; }

.flickity-rtl .flickity-prev-next-button.next {
  right: auto;
  left: 10px; }

.flickity-prev-next-button:disabled {
  filter: alpha(opacity=30);
  /* IE8 */
  opacity: 0.3;
  cursor: auto; }

.flickity-prev-next-button svg {
  position: absolute;
  left: 20%;
  top: 20%;
  width: 60%;
  height: 60%; }

.flickity-prev-next-button .arrow {
  fill: #333; }

/* color & size if no SVG - IE8 and Android 2.3 */
.flickity-prev-next-button.no-svg {
  color: #333;
  font-size: 26px; }

/* ---- page dots ---- */
.flickity-page-dots {
  position: absolute;
  width: 100%;
  bottom: -25px;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;
  line-height: 1; }

.flickity-rtl .flickity-page-dots {
  direction: rtl; }

.flickity-page-dots .dot {
  display: inline-block;
  width: 10px;
  height: 10px;
  margin: 0 8px;
  background: #333;
  border-radius: 50%;
  filter: alpha(opacity=25);
  /* IE8 */
  opacity: 0.25;
  cursor: pointer; }

.flickity-page-dots .dot.is-selected {
  filter: alpha(opacity=100);
  /* IE8 */
  opacity: 1; }

@keyframes fadeUp {
  0% {
    opacity: 0;
    transform: translateY(60px); }
  100% {
    opacity: 1;
    transform: none; } }

/*! sanitize.css v3.3.0 | CC0 1.0 Public Domain | github.com/10up/sanitize.css */
/*
 * Normalization
 */
abbr[title] {
  text-decoration: underline;
  text-decoration: underline dotted; }

audio:not([controls]) {
  display: none; }

b,
strong {
  font-weight: bolder; }

button {
  -webkit-appearance: button;
  overflow: visible; }

button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0; }

button:-moz-focusring,
input:-moz-focusring {
  outline: 1px dotted ButtonText; }

button,
select {
  text-transform: none; }

details {
  display: block; }

hr {
  overflow: visible; }

html {
  -ms-overflow-style: -ms-autohiding-scrollbar;
  overflow-y: scroll;
  -webkit-text-size-adjust: 100%; }

input {
  -webkit-border-radius: 0; }
  input[type="button"], input[type="reset"], input[type="submit"] {
    -webkit-appearance: button; }
  input[type="number"] {
    width: auto; }
  input[type="search"] {
    -webkit-appearance: textfield; }
    input[type="search"]::-webkit-search-cancel-button, input[type="search"]::-webkit-search-decoration {
      -webkit-appearance: none; }

main {
  display: block; }

pre {
  overflow: auto; }

progress {
  display: inline-block; }

summary {
  display: block; }

svg:not(:root) {
  overflow: hidden; }

template {
  display: none; }

textarea {
  overflow: auto; }

[hidden] {
  display: none; }

/*
 * Universal inheritance
 */
*,
::before,
::after {
  box-sizing: inherit; }

* {
  font-size: inherit;
  line-height: inherit; }

::before,
::after {
  text-decoration: inherit;
  vertical-align: inherit; }

button,
input,
select,
textarea {
  font-family: inherit;
  font-style: inherit;
  font-weight: inherit; }

/*
 * Opinionated defaults
 */
* {
  margin: 0;
  padding: 0; }

*,
::before,
::after {
  border-style: solid;
  border-width: 0; }

a,
area,
button,
input,
label,
select,
textarea,
[tabindex] {
  touch-action: manipulation; }

select {
  -moz-appearance: none;
  -webkit-appearance: none; }
  select::-ms-expand {
    display: none; }
  select::-ms-value {
    color: currentColor; }

svg {
  fill: currentColor; }

[aria-busy="true"] {
  cursor: progress; }

[aria-controls] {
  cursor: pointer; }

[aria-disabled] {
  cursor: default; }

[hidden][aria-hidden="false"] {
  clip: rect(0 0 0 0);
  display: inherit;
  position: absolute; }
  [hidden][aria-hidden="false"]:focus {
    clip: auto; }

/*
 * Configurable defaults
 */
* {
  background-repeat: no-repeat; }

:root {
  background-color: #ffffff;
  box-sizing: border-box;
  color: #000000;
  cursor: default;
  font: 66.66667% sans-serif; }

a {
  text-decoration: none; }

audio,
canvas,
iframe,
img,
svg,
video {
  vertical-align: middle; }

button,
input,
select,
textarea {
  background-color: transparent;
  color: inherit; }

button,
[type="button"],
[type="date"],
[type="datetime"],
[type="datetime-local"],
[type="email"],
[type="month"],
[type="number"],
[type="password"],
[type="reset"],
[type="search"],
[type="submit"],
[type="tel"],
[type="text"],
[type="time"],
[type="url"],
[type="week"],
select,
textarea {
  min-height: 1.5em; }

code,
kbd,
pre,
samp {
  font-family: monospace, monospace; }

nav ol,
nav ul {
  list-style: none; }

small {
  font-size: 75%; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

textarea {
  resize: vertical; }

::-moz-selection {
  background-color: #b3d4fc;
  color: #ffffff;
  text-shadow: none; }

::selection {
  background-color: #b3d4fc;
  color: #ffffff;
  text-shadow: none; }

:root {
  font-size: 100%;
  overflow-x: hidden;
  word-wrap: break-word; }

[data-whatinput='keyboard'] :focus {
  outline: 0.125rem solid #4d90fe;
  outline-offset: -0.125rem; }

[data-whatinput='mouse'] :focus,
[data-whatinput='touch'] :focus {
  outline: 0; }

input[type='radio'] {
  border: 0.0625rem solid #57595B;
  border-radius: 0.5rem;
  background-color: #fff; }
  input[type='radio']:checked {
    background: rgba(0, 0, 0, 0.75); }

input[type='search'] {
  appearance: none; }

button {
  cursor: pointer; }

img {
  display: block;
  max-width: 100%;
  height: auto;
  position: relative; }
  img:before {
    content: ' '; }
  img:after {
    content: attr(alt);
    color: #fff;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #000; }

svg:not(:root) {
  display: inherit; }

::-webkit-media-controls {
  display: none !important; }

.svgstore {
  display: block;
  position: relative;
  pointer-events: none; }
  .svgstore svg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }

.svgstore--alert {
  padding-bottom: 100%; }

.svgstore--angle-left {
  padding-bottom: 100%; }

.svgstore--angle-right {
  padding-bottom: 100%; }

.svgstore--brandeis-logo {
  padding-bottom: 31.03448%; }

.svgstore--checkmark {
  padding-bottom: 86.66667%; }

.svgstore--chevron-down-alt {
  padding-bottom: 100%; }

.svgstore--chevron-down {
  padding-bottom: 100%; }

.svgstore--chevron-up {
  padding-bottom: 100%; }

.svgstore--close {
  padding-bottom: 100%; }

.svgstore--comment {
  padding-bottom: 100%; }

.svgstore--facebook {
  padding-bottom: 100%; }

.svgstore--heart {
  padding-bottom: 100%; }

.svgstore--instagram {
  padding-bottom: 100%; }

.svgstore--linkedin {
  padding-bottom: 100%; }

.svgstore--logo {
  padding-bottom: 14.25891%; }

.svgstore--menu {
  padding-bottom: 100%; }

.svgstore--minus {
  padding-bottom: 100%; }

.svgstore--pause {
  padding-bottom: 100%; }

.svgstore--play-alt {
  padding-bottom: 100%; }

.svgstore--plus {
  padding-bottom: 100%; }

.svgstore--search {
  padding-bottom: 100%; }

.svgstore--shield {
  padding-bottom: 100%; }

.svgstore--twitter {
  padding-bottom: 100%; }

.svgstore--youtube {
  padding-bottom: 100%; }

body {
  font-family: "merriweather", serif;
  font-size: 1.0625rem;
  line-height: 1.705;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased; }

h1,
h2,
h3,
.feed-title,
h4,
h5,
h6,
.h1-hero,
.h1,
.h2,
.h3,
.h4,
.faculty__listing__name,
.h5,
.h6 {
  color: #2f2f2f;
  font-family: "Whitney SSm A", "Whitney SSm B", sans-serif; }

h1,
.h1 {
  margin-top: 5rem;
  margin-bottom: 2.5rem;
  font-size: 3.75rem;
  font-weight: 700;
  line-height: 1.2; }

.h1-hero {
  font-size: 3.5rem;
  font-weight: 600;
  line-height: 1; }
  @media (min-width: 48em) {
    .h1-hero {
      font-size: 4rem; } }

h2,
.h2 {
  margin-top: 5rem;
  margin-bottom: 1.25rem;
  font-size: 2.625rem;
  font-weight: 600;
  line-height: 1.125; }

h3, .feed-title,
.h3 {
  margin-top: 2.5rem;
  margin-bottom: 1.25rem;
  font-size: 2rem;
  font-weight: 600;
  line-height: 1.071; }
  h3 a, .feed-title a,
  .h3 a {
    font-weight: 400; }
    h3 a:hover, .feed-title a:hover,
    .h3 a:hover {
      color: #003478; }

h4,
.h4,
.faculty__listing__name {
  margin-top: 2.5rem;
  margin-bottom: 1.25rem;
  font-size: 1.75rem;
  font-weight: 400;
  line-height: 1.142; }
  h4 a,
  .h4 a, .faculty__listing__name a {
    font-weight: 400; }
    h4 a:hover,
    .h4 a:hover, .faculty__listing__name a:hover {
      color: #003478; }

h5,
.h5 {
  margin-top: 2.5rem;
  margin-bottom: 1.25rem;
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 1.166; }
  h5 a,
  .h5 a {
    font-weight: 600; }
    h5 a:hover,
    .h5 a:hover {
      color: #003478; }

h6,
.h6 {
  margin-top: 2.5rem;
  margin-bottom: 1.25rem;
  font-size: 1.25rem;
  font-weight: 600;
  line-height: 1.35; }
  h6 a,
  .h6 a {
    font-weight: 600; }
    h6 a:hover,
    .h6 a:hover {
      color: #003478; }

.heading--light {
  font-weight: 500; }
  .heading--light a {
    font-weight: 500; }

strong {
  font-weight: 700; }

.medium {
  font-weight: 500; }

p {
  margin-top: 1.25rem;
  margin-bottom: 1.25rem; }
  .main p a:not([class]) {
    border-bottom: 1px solid #003478;
    padding-bottom: .125em; }
    .main p a:not([class]):hover {
      border-color: #0B7886;
      text-decoration: none;
      color: #0B7886; }

.p--small, .accordion__content, .faculty__listing p:not([class]) {
  font-family: "merriweather", serif;
  margin-top: 0.75rem;
  margin-bottom: 0.75rem;
  font-size: 0.9375rem;
  line-height: 1.8; }

.p--large, .form__label {
  font-family: "Whitney SSm A", "Whitney SSm B", sans-serif;
  font-size: 1.1875rem;
  line-height: 1.736;
  font-weight: 600; }
  .p--large a, .form__label a {
    font-weight: 600; }

.p--micro {
  font-size: 0.75rem !important; }

.p--sans-serif, .caption, .caption--alt {
  font-family: "Whitney SSm A", "Whitney SSm B", sans-serif; }

.p--xlarge {
  font-size: 1.75rem;
  font-weight: 500;
  line-height: 1.4; }

a {
  color: #003478;
  font-weight: 600; }
  a:hover {
    text-decoration: underline; }

blockquote {
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
  padding: 1.25rem;
  background: #f5f4f0; }
  blockquote > :first-child {
    margin-top: 0; }
  blockquote > :last-child {
    margin-bottom: 0; }

hr {
  margin-top: 3rem;
  margin-bottom: 3rem;
  border-top: 0.0625rem solid #57595B; }
  hr + .block {
    margin-top: 3.75rem;
    margin-bottom: 3.75rem; }

ul,
ol {
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
  padding-left: 1.25rem; }
  ul > :first-child,
  ol > :first-child {
    margin-top: 0; }
  ul > :last-child,
  ol > :last-child {
    margin-bottom: 0; }

.ul--clean {
  list-style: none;
  padding-left: 0; }

li {
  margin-bottom: 0.75rem; }

li:not([class]) a:not([class]) {
  font-weight: 600;
  border-bottom: 1px solid #003478;
  padding-bottom: .125em; }
  li:not([class]) a:not([class]):hover {
    border-color: #0B7886;
    text-decoration: none;
    color: #0B7886; }

.caption {
  margin-top: 0.75rem;
  margin-bottom: 0.75rem;
  font-size: 1rem;
  line-height: 1.8; }

.caption--alt {
  margin-top: 0.75rem;
  margin-bottom: 0.75rem;
  font-size: 1rem;
  line-height: 1.8; }
  .main .caption--alt a:not([class]) {
    border-bottom: 1px solid #003478;
    padding-bottom: .125em; }
    .main .caption--alt a:not([class]):hover {
      border-color: #0B7886;
      text-decoration: none;
      color: #0B7886; }

.inline-image img {
  display: inline-block; }

.inline-image a {
  padding: 0.25rem;
  display: inline-block; }

.large--serif {
  font-size: 1.375rem;
  font-weight: lighter;
  line-height: 1.7333;
  font-family: "merriweather", serif; }

@media (min-width: 64em) {
  .two__column__list {
    column-count: 2;
    column-gap: 3rem; } }

.callout {
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
  padding: 1.25rem;
  background: #f5f4f0;
  border-top: 0.25rem solid #57595B; }
  .callout > :first-child {
    margin-top: 0; }
  .callout > :last-child {
    margin-bottom: 0; }

.eyebrow {
  font-family: "Whitney SSm A", "Whitney SSm B", sans-serif;
  font-weight: 600;
  text-transform: uppercase; }

.wrap {
  max-width: 90rem;
  margin-right: auto;
  margin-left: auto;
  padding-right: 1rem;
  padding-left: 1rem; }
  .wrap::before, .wrap::after {
    display: table;
    width: 0;
    content: ''; }
  .wrap::after {
    clear: both; }
  @media (min-width: 48em) {
    .wrap {
      padding-right: 2rem;
      padding-left: 2rem; } }
  @media (min-width: 64.0625em) {
    .wrap {
      padding-right: 3rem;
      padding-left: 3rem; } }

.wrap--wide {
  max-width: 96rem; }

.wrap--narrow {
  max-width: 70rem; }

.wrap--slim {
  max-width: 58rem; }

.wrap--relative {
  position: relative; }

.main,
.sidebar {
  margin-top: 3.25rem;
  margin-bottom: 3.25rem; }
  .main > :first-child,
  .sidebar > :first-child {
    margin-top: 0; }
  .main > :last-child,
  .sidebar > :last-child {
    margin-bottom: 0; }

.main {
  margin-top: 2.5rem;
  margin-bottom: 2.5rem; }
  @media (min-width: 64.0625em) {
    .main {
      margin-top: 3.25rem;
      margin-bottom: 3.25rem;
      width: calc(100% - 22rem);
      float: right; } }
  @media (min-width: 64.0625em) {
    .main {
      width: calc(100% - 24rem); } }

@media (min-width: 64.0625em) {
  .main--center {
    margin-right: auto;
    margin-left: auto;
    float: none; } }

.sidebar {
  font-size: 0.9375rem; }
  @media (min-width: 64.0625em) {
    .sidebar {
      width: 18rem;
      float: left; } }

@media (min-width: 64.0625em) {
  .main + .sidebar + .main {
    margin-top: 0; } }

.align-left,
.align-right {
  margin-bottom: 1.25rem; }
  @media (min-width: 48em) {
    .align-left,
    .align-right {
      max-width: 40%; } }

@media (min-width: 48em) {
  .align-left {
    margin-right: 1.25rem;
    float: left; } }

@media (min-width: 48em) {
  .align-right {
    margin-left: 1.25rem;
    float: right; } }

@media (min-width: 48em) {
  .align-small {
    max-width: 25%; } }

@media (min-width: 48em) {
  .align-zero {
    margin-bottom: 0; } }

.full-width {
  margin-top: 2.5rem;
  margin-bottom: 2.5rem; }

.text-center {
  text-align: center; }

.text-left {
  text-align: left; }

.text-right {
  text-align: right; }

.semibold {
  font-weight: 600; }

.hide {
  position: absolute;
  left: -999rem; }

.triangle-tab {
  position: relative; }
  .triangle-tab::before {
    position: absolute;
    top: 0;
    left: 50%;
    margin-left: -1.25rem;
    border-top: 1.25rem solid #003478;
    border-right: 1.25rem solid transparent;
    border-left: 1.25rem solid transparent;
    content: ''; }

.indent {
  margin-top: 3rem;
  margin-bottom: 3rem;
  padding-left: 1.25rem;
  border-left: 0.5rem solid #003478; }
  .indent > :first-child {
    margin-top: 0; }
  .indent > :last-child {
    margin-bottom: 0; }

.skip {
  position: absolute;
  left: -999rem; }
  .skip:focus {
    position: fixed;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
    z-index: 5; }

.skip-carousel {
  position: absolute;
  left: -999rem; }
  .skip-carousel:focus {
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    z-index: 5;
    text-align: center; }

.shield-icon {
  margin: 2rem auto;
  width: 6.25rem;
  height: 6.25rem; }

.only-mobile {
  display: block; }
  @media (min-width: 64.0625em) {
    .only-mobile {
      display: none; } }

.only-desktop {
  display: none; }
  @media (min-width: 64.0625em) {
    .only-desktop {
      display: block; } }

.clearfix::before, .clearfix::after {
  display: table;
  width: 0;
  content: ''; }

.clearfix::after {
  clear: both; }

.fadeUp {
  opacity: 0; }

.fadeUp--active {
  animation: fadeUp 800ms cubic-bezier(0.645, 0.045, 0.355, 1) forwards; }

.zoomin {
  overflow: hidden; }
  .zoomin img {
    transition: transform 300ms ease; }
  .zoomin:hover img {
    transform: scale(1.1); }

.accordion-group {
  margin-top: 2.5rem;
  margin-bottom: 2.5rem; }
  .accordion-group > :first-child {
    margin-top: 0; }
  .accordion-group > :last-child {
    margin-bottom: 0; }
  @media (min-width: 64.0625em) {
    .tab-accordions .accordion-group {
      display: none; } }

.accordion {
  position: relative;
  overflow: hidden;
  margin-top: 1.25rem;
  margin-bottom: 1.25rem; }
  .tab-accordions .accordion {
    margin: 0.125rem 0; }

.accordion__heading__toggle {
  font-size: 1.75rem;
  margin: 0;
  line-height: 1.7059; }

.accordion__toggle,
.accordion__content {
  padding: 1.25rem;
  background: #f5f4f0; }

.accordion__toggle {
  display: block;
  color: #003478;
  font-family: "Whitney SSm A", "Whitney SSm B", sans-serif;
  font-weight: 400; }
  .tab-accordions .accordion__toggle {
    position: relative;
    border-radius: 0.1875rem;
    background-color: #003478;
    font-size: 0.875rem;
    font-weight: 600;
    text-transform: uppercase;
    color: #fff; }
  .accordion__toggle:hover {
    text-decoration: none; }
    .accordion__toggle:hover .accordion__icon {
      background-color: #0B7886; }

.accordion__icon {
  border-radius: 50%;
  background-color: #003478;
  width: 2.875rem;
  margin-left: 1.25rem;
  padding: 0.75rem;
  float: right;
  color: #fff;
  pointer-events: none; }

.accordion__icon--alt {
  display: block;
  position: absolute;
  top: 50%;
  right: 1rem;
  transform: translateY(-50%);
  width: 1.25rem;
  height: 1.25rem;
  color: #fff; }
  .accordion--active .accordion__icon--alt {
    color: #fff;
    transform: rotate(180deg) translateY(50%); }

.accordion__icon--active {
  display: none; }
  .accordion--active .accordion__icon--active {
    display: block; }

.accordion__icon--inactive {
  display: block; }
  .accordion--active .accordion__icon--inactive {
    display: none; }

.accordion__content {
  position: absolute;
  left: -999rem;
  top: 0;
  margin-top: 0.125rem;
  visibility: hidden; }
  .accordion__content > :first-child {
    margin-top: 0; }
  .accordion__content > :last-child {
    margin-bottom: 0; }
  .accordion--active .accordion__content {
    position: static;
    left: auto;
    visibility: visible; }
  .accordion__content a:not([class]) {
    border-bottom: 1px solid #003478;
    padding-bottom: .125em; }
    .accordion__content a:not([class]):hover {
      border-color: #0B7886;
      text-decoration: none;
      color: #0B7886; }
  .tab-accordions .accordion__content {
    background: transparent;
    padding: 1rem 0;
    margin: 0; }

.accordion__heading {
  margin-top: 2.5rem;
  margin-bottom: 1.25rem;
  font-size: 1.125rem;
  font-weight: 500;
  line-height: 1.3333; }

.accordion__expand {
  text-align: right;
  display: block;
  font-family: "Whitney SSm A", "Whitney SSm B", sans-serif;
  font-size: 1.25rem;
  font-weight: 600; }

.alert {
  padding: 2.5rem 0;
  background: #2f2f2f;
  color: #fff; }

.alert__container {
  position: relative;
  padding: 0 2.5rem; }

.alert__icon {
  position: absolute;
  top: 0.375rem;
  left: 0;
  width: 1.5rem;
  color: #fffb00; }

.alert__text {
  font-family: "Whitney SSm A", "Whitney SSm B", sans-serif;
  font-size: 1.375rem;
  font-weight: 500; }
  .alert__text a {
    color: #fff;
    text-decoration: underline; }

.alert__label {
  color: #fffb00;
  text-transform: uppercase; }

.alert__close {
  position: absolute;
  top: 0.5rem;
  right: 0;
  width: 1.25rem;
  color: #fff; }

.banner {
  position: relative;
  margin: auto; }

.banner__image {
  width: 100%;
  min-height: 10rem; }

.banner__gradient {
  display: none;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 30%;
  background-image: linear-gradient(transparent, rgba(0, 0, 0, 0.75));
  content: ''; }
  @media (min-width: 48em) {
    .banner__gradient {
      display: block; } }

.banner__text {
  margin: 0 -1rem;
  padding: 2.5rem 1rem;
  background: #f5f4f0; }
  .banner__text > :first-child {
    margin-top: 0; }
  .banner__text > :last-child {
    margin-bottom: 0; }
  .banner__text p {
    margin-top: 0.75rem;
    margin-bottom: 0.75rem;
    font-size: 0.9375rem;
    line-height: 1.8;
    font-family: "Whitney SSm A", "Whitney SSm B", sans-serif;
    font-weight: 500; }
  @media (min-width: 48em) {
    .banner__text {
      display: block;
      position: absolute;
      bottom: 2rem;
      margin: 0;
      padding: 0 2rem 0 0;
      background: none;
      color: #fff;
      text-shadow: 0.1rem 0.1rem 0.1rem #000; } }
  @media (min-width: 64.0625em) {
    .banner__text {
      max-width: 50%; } }
  @media (min-width: 90em) {
    .banner__text {
      padding-right: 3rem; } }

.block {
  margin-top: 5rem;
  margin-bottom: 5rem; }
  .block::before, .block::after {
    display: table;
    width: 0;
    content: ''; }
  .block::after {
    clear: both; }

.block__text {
  overflow: hidden; }
  .block__text > :first-child {
    margin-top: 0; }
  .block__text > :last-child {
    margin-bottom: 0; }

.block__text--alt p {
  margin-top: 0.75rem;
  margin-bottom: 0.75rem;
  font-size: 0.9375rem;
  line-height: 1.8; }

.breadcrumbs-title {
  margin-bottom: 0.25rem; }
  .breadcrumbs-title a {
    color: inherit; }

.breadcrumbs {
  display: none;
  font-family: "Whitney SSm A", "Whitney SSm B", sans-serif;
  font-size: 0.75rem;
  font-weight: 400; }
  @media (min-width: 64.0625em) {
    .breadcrumbs {
      display: block;
      margin-top: 0.5rem;
      margin-bottom: -0.25rem !important; }
      .block__text .breadcrumbs {
        margin-bottom: 0 !important; } }

.breadcrumbs__item {
  color: #003478 !important;
  font-weight: 400; }

.breadcrumbs__item--active {
  font-weight: 600;
  color: #000 !important; }

.breadcrumb__wrapper {
  margin-bottom: 3rem; }

.button-group {
  margin-top: 2.5rem;
  margin-bottom: 2.5rem; }
  .button-group > :first-child {
    margin-top: 0; }
  .button-group > :last-child {
    margin-bottom: 0; }

.button-stack {
  margin: 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  margin-left: -1rem;
  margin-right: -1rem; }

.button-stack__item {
  display: flex;
  flex-grow: 1;
  margin-bottom: 2rem !important;
  margin-left: 1rem;
  margin-right: 1rem; }
  @media (min-width: 32em) {
    .button-stack--3 .button-stack__item {
      flex-grow: 0;
      width: calc(33% - 2rem); } }
  @media (min-width: 32em) {
    .button-stack--2 .button-stack__item {
      flex-grow: 0;
      width: calc(50% - 2rem); } }
  .button-stack__item .button {
    flex-grow: 1;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center; }

.button {
  display: inline-block;
  padding: 0.85rem 1.875rem;
  border: 0.0625rem solid transparent;
  border-radius: 0.1875rem;
  color: #003478;
  font-family: "Whitney SSm A", "Whitney SSm B", sans-serif;
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 1.1667;
  text-transform: uppercase;
  background-color: #fff;
  border-color: #003478; }
  .button:hover, .button:focus {
    background-color: #0B7886;
    border-color: #0B7886;
    color: #fff;
    text-decoration: none; }
    [data-whatinput='keyboard'] .button:hover, [data-whatinput='keyboard'] .button:focus {
      outline-offset: 0.125rem; }

.button--secondary {
  border-color: #003478;
  color: #fff;
  background-color: #003478; }
  .button--secondary:hover, .button--secondary:focus {
    background-color: #0B7886;
    border-color: #0B7886;
    color: #fff; }

.button--teal {
  border-color: #0B7886;
  background: #0B7886;
  color: #fff; }
  .button--teal:hover, .button--teal:focus {
    background-color: #fff;
    color: #003478;
    border-color: #fff; }

.button--text {
  padding: 0;
  border: 0;
  color: #003478;
  background-color: transparent; }
  .button--text:hover, .button--text:focus {
    color: #003478;
    text-decoration: underline;
    background-color: transparent; }

.button--full {
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
  display: block;
  width: 100%;
  text-align: center; }

.button--short {
  padding: 0.5rem 1.5rem;
  text-transform: none; }

.button--link {
  font-family: "Whitney SSm A", "Whitney SSm B", sans-serif;
  font-weight: 600;
  font-size: 0.8125rem;
  text-transform: uppercase;
  color: #003478;
  border-bottom: 1px solid #003478;
  padding-bottom: .125em;
  line-height: .9; }
  .button--link:hover, .button--link:focus {
    border-color: #0B7886;
    color: #0B7886;
    text-decoration: none; }

.card {
  padding: 1.25rem;
  background: #f5f4f0;
  color: #2f2f2f;
  font-family: "Whitney SSm A", "Whitney SSm B", sans-serif; }
  .module--beige .card,
  .module--gray-light .card {
    background: #fff; }

.card--alt {
  background: #fff;
  color: #2f2f2f;
  font-family: "Whitney SSm A", "Whitney SSm B", sans-serif;
  padding: 1.25rem 0; }

.card--flex {
  display: flex;
  flex-direction: column;
  height: 100%; }

.card a:not([class]),
.card--alt a:not([class]) {
  display: inline-block;
  font-size: 1.25rem;
  line-height: 1.35;
  margin-bottom: 1rem; }

.card__tag {
  font-size: 0.875rem;
  font-weight: 400;
  margin-top: auto;
  text-decoration: underline; }
  .card__tag:hover {
    color: #0B7886; }

.card__header {
  margin-bottom: 1.25rem;
  padding-bottom: 0.25rem;
  border-bottom: 0.25rem solid #57595B; }
  .home .card__header {
    border-color: #0B7886; }

.card__meta {
  font-family: "Whitney SSm A", "Whitney SSm B", sans-serif;
  font-weight: 600;
  font-size: 0.875rem;
  text-transform: uppercase; }

.carousel {
  background: #f5f4f0; }

.dropdown {
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
  position: relative;
  width: 20rem;
  max-width: 100%; }

.dropdown--full {
  width: 100%; }

.dropdown__toggle {
  position: relative;
  display: block;
  padding-right: 3rem;
  padding-left: 0.75rem;
  text-transform: none;
  font-size: 0.9375rem;
  background-color: #fff;
  border-color: #003478;
  color: #003478; }
  .toggle__form__dropdown .dropdown__toggle {
    background-color: transparent; }
  .dropdown__toggle:hover, .dropdown__toggle:focus {
    background-color: #003478;
    border-color: #003478;
    color: #fff; }
  .dropdown--active .dropdown__toggle {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border-bottom-color: transparent;
    border-top-color: #000;
    background: #f5f4f0;
    color: #003478; }
  .toggle__form__dropdown .dropdown--active .dropdown__toggle {
    background-color: #003478;
    color: #fff; }

.dropdown__icon {
  position: absolute;
  top: 50%;
  right: 1rem;
  transform: translateY(-50%);
  width: 1.25rem; }
  .dropdown--active .dropdown__icon {
    transform: rotate(180deg) translateY(50%); }

.dropdown__list {
  position: absolute;
  left: -999rem;
  width: 100%;
  margin-top: -0.0625rem;
  margin-bottom: 0;
  padding: 0 0.75rem 0.75rem;
  border: 0.0625rem solid #000;
  border-top: 0;
  border-bottom-left-radius: 0.1875rem;
  border-bottom-right-radius: 0.1875rem;
  background: #f5f4f0;
  list-style: none;
  box-shadow: 0 0.375rem 0.375rem rgba(0, 0, 0, 0.1);
  visibility: hidden;
  z-index: 1; }
  .dropdown--active .dropdown__list {
    position: static;
    left: auto;
    position: absolute;
    visibility: visible; }
  .toggle__form__dropdown .dropdown--active .dropdown__list {
    background-color: #003478; }
    .toggle__form__dropdown .dropdown--active .dropdown__list .button--text {
      color: #fff; }

.dropdown__item {
  margin: 0; }

.dropdown__link {
  display: block;
  font-size: 0.9375rem;
  text-transform: none;
  padding: 0.65rem 0; }

.faculty__listing__heading__first {
  margin-top: 0; }

.faculty__listing {
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
  border-bottom: 0.0625rem solid #57595B;
  padding: 1.25rem 0; }
  .faculty__listing > :first-child {
    margin-top: 0; }
  .faculty__listing > :last-child {
    margin-bottom: 0; }

.faculty__listing__image {
  width: 75px;
  height: 75px;
  float: right;
  margin: 0 0 1rem 1rem; }
  @media (min-width: 48em) {
    .faculty__listing__image {
      width: 145px;
      height: 146px;
      margin: 0 0 1rem 1rem; } }
  @media (min-width: 64em) {
    .faculty__listing__image {
      width: 170px;
      height: 170px; } }

.faculty__listing__name {
  color: #003478; }
  .faculty__listing__name:hover {
    color: #0B7886; }

.faculty__listing__title,
.faculty__listing__contact,
.faculty__listing__hours {
  font-family: "Whitney SSm A", "Whitney SSm B", sans-serif;
  margin-top: 0.25rem;
  margin-bottom: 0rem;
  font-size: 0.9375rem;
  line-height: 1.8; }

.faculty__listing__title {
  font-weight: 700; }

.faculty__listing__contact,
.faculty__listing__hours {
  font-family: "Whitney SSm A", "Whitney SSm B", sans-serif; }
  .faculty__listing__contact span:not(:last-child):after,
  .faculty__listing__hours span:not(:last-child):after {
    content: "\a";
    white-space: pre; }
    @media (min-width: 48em) {
      .faculty__listing__contact span:not(:last-child):after,
      .faculty__listing__hours span:not(:last-child):after {
        content: ' |'; } }

.faculty__listing__email {
  font-weight: 500; }

.feature {
  margin-top: 2.5rem;
  margin-bottom: 2.5rem; }
  .feature::before, .feature::after {
    display: table;
    width: 0;
    content: ''; }
  .feature::after {
    clear: both; }
  @media (min-width: 48em) {
    .feature {
      display: flex; } }

.feature--flip {
  margin-bottom: 4rem; }

@media (min-width: 48em) {
  .feature--full .feature__media, .feature--full
  .feature__text {
    width: calc(50% - 2.5rem); } }

.feature__media {
  margin-bottom: 1.25rem; }
  .feature__media > :first-child {
    margin-top: 0; }
  .feature__media > :last-child {
    margin-bottom: 0; }
  @media (min-width: 48em) {
    .feature__media {
      width: calc(40% - 1.25rem);
      margin-bottom: 0;
      float: left; }
      .feature--flip .feature__media {
        width: calc(60% - 2rem); } }

.feature__text > :first-child {
  margin-top: 0; }

.feature__text > :last-child {
  margin-bottom: 0; }

@media (min-width: 48em) {
  .feature__text {
    align-self: center;
    width: calc(60% - 1.25rem);
    margin-left: auto; }
    .feature--flip .feature__text {
      width: calc(40% - 2rem); } }

.feature__text--alt p {
  margin-top: 0.75rem;
  margin-bottom: 0.75rem;
  font-size: 0.9375rem;
  line-height: 1.8; }

.feed-group {
  margin-top: 5rem;
  margin-bottom: 5rem; }
  .feed-group > :first-child {
    margin-top: 0; }
  .feed-group > :last-child {
    margin-bottom: 0; }

.feed-title {
  margin-bottom: 2.5rem;
  padding-top: 1.25rem;
  border-top: 0.25rem solid #57595B; }

.feed {
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
  padding-bottom: 1.25rem;
  border-bottom: 0.0625rem solid #003478; }
  .feed::before, .feed::after {
    display: table;
    width: 0;
    content: ''; }
  .feed::after {
    clear: both; }
  .feed:first-child,
  .feed-title + .feed {
    padding-top: 0;
    border-top: 0; }

.feed__text {
  overflow: hidden;
  font-size: 0.9375rem; }
  .feed__text > :first-child {
    margin-top: 0; }
  .feed__text > :last-child {
    margin-bottom: 0; }

.feed__text--alt p {
  margin-top: 0.75rem;
  margin-bottom: 0.75rem;
  font-size: 0.9375rem;
  line-height: 1.8; }

.feed__image {
  position: relative; }
  .feed__image a {
    display: block; }
    [data-whatinput='keyboard'] .feed__image a:focus {
      outline-offset: 0; }

.flickity-page-dots {
  bottom: 0.5rem;
  width: 100%;
  font-size: 0; }
  .flickity-page-dots .dot {
    width: 1rem;
    height: 1rem;
    margin: 0 0.25rem;
    border: 0.0625rem solid;
    background: rgba(255, 255, 255, 0.001);
    color: #003478;
    opacity: 1; }
    .overlay--image .flickity-page-dots .dot {
      color: #fff; }
    .flickity-page-dots .dot:first-child {
      margin-left: 0; }
    .flickity-page-dots .dot:last-child {
      margin-right: 0; }
    .flickity-page-dots .dot.is-selected {
      background: currentColor; }

.flickity-prev-next-button {
  width: 3.5rem;
  height: 3.5rem;
  padding: 0.5rem;
  border-radius: 0;
  background: rgba(255, 255, 255, 0.001); }
  .flickity-prev-next-button .flickity-button-icon {
    position: static;
    width: auto;
    height: auto; }
  .flickity-prev-next-button:active {
    opacity: 1; }
  .flickity-prev-next-button:hover {
    background: #0B7886; }
  .flickity-prev-next-button:disabled {
    opacity: 0; }
  .flickity-prev-next-button.previous {
    left: 0; }
  .flickity-prev-next-button.next {
    right: 0; }
  .flickity-prev-next-button .arrow {
    fill: rgba(255, 255, 255, 0.75); }

.floatnav {
  margin-top: 2.5rem;
  margin-bottom: 2.5rem;
  padding: 0 1rem;
  list-style: none;
  z-index: 3; }
  @media (min-width: 48em) {
    .floatnav {
      position: absolute;
      top: 2rem;
      right: 0;
      margin: 0;
      padding: 0; }
      .hero--image .floatnav,
      .hero-slider-alt .floatnav {
        position: static;
        max-width: 300px; } }
  @media (min-width: 90em) {
    .floatnav {
      top: 3rem; } }

@media (min-width: 48em) {
  .floatnav--module {
    margin-top: 5rem;
    margin-bottom: 5rem;
    position: static;
    padding: 0 1rem; } }

@media (min-width: 64.0625em) {
  .floatnav--module {
    text-align: center; } }

@media (min-width: 90em) {
  .floatnav--module {
    position: absolute;
    top: 5rem;
    right: 0;
    margin: 0;
    padding: 0; } }

@media (min-width: 48em) {
  .floatnav__item {
    margin: 0.25rem 0; }
    .hero--image .floatnav__item,
    .hero-slider-alt .floatnav__item {
      margin-bottom: .75rem; } }

@media (min-width: 48em) {
  .floatnav--module .floatnav__item {
    margin: 0.75rem 0; } }

@media (min-width: 64.0625em) {
  .floatnav--module .floatnav__item {
    display: inline-block;
    margin: 0 0.625rem; } }

@media (min-width: 90em) {
  .floatnav--module .floatnav__item {
    display: block;
    margin: 0.25rem 0; } }

.floatnav__link {
  width: 100%;
  text-align: center; }
  .floatnav--module .floatnav__link {
    border-color: transparent;
    background: #fff; }
    @media (min-width: 48em) {
      .floatnav--module .floatnav__link {
        border-radius: 0.1875rem;
        text-align: center; } }
    @media (min-width: 90em) {
      .floatnav--module .floatnav__link {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        text-align: left; } }

.footer {
  background: #57595B;
  color: #fff;
  font-family: "Whitney SSm A", "Whitney SSm B", sans-serif;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5; }
  .footer p {
    margin: 0; }
  .footer a:not([class^=button]) {
    color: inherit;
    font-weight: 700; }

.footer__top {
  padding-top: 5rem;
  padding-bottom: 1.5rem;
  border-bottom: 0.0625rem solid #797a7c; }

.footer__bottom {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem; }
  @media (min-width: 72em) {
    .footer__bottom {
      padding-bottom: 5rem; } }

@media (min-width: 72em) {
  .footer__container {
    display: flex;
    align-content: center;
    justify-content: space-between; } }

.footer__nav {
  padding: 0;
  margin: 0;
  list-style: none; }
  .footer__top .footer__nav {
    margin-top: 2rem;
    padding-top: 2rem;
    border-top: 0.125rem solid #797a7c; }
  @media (min-width: 72em) {
    .footer__top .footer__nav {
      border-top: 0;
      margin-top: 0;
      padding-top: 0;
      width: calc(100% - 13rem);
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap; } }

.footer__nav__item {
  margin-bottom: 0.5rem !important;
  margin-top: 0.5rem !important; }
  @media (min-width: 72em) {
    .footer__nav__item {
      display: inline-block;
      margin-left: 2rem; }
      .footer__bottom .footer__nav__item:first-child {
        margin-left: 0; } }

.footer__nav__link {
  letter-spacing: 1.3px;
  text-transform: uppercase; }

.footer__social {
  margin-top: 2rem;
  padding: 0;
  list-style: none; }
  .footer__social::before, .footer__social::after {
    display: table;
    width: 0;
    content: ''; }
  .footer__social::after {
    clear: both; }
  @media (min-width: 72em) {
    .footer__social {
      margin-top: 0.5rem; } }

.footer__social__item {
  margin: 0 0 0 1.25rem;
  float: left; }
  .footer__social__item:first-child {
    margin-left: 0; }

.footer__social__link {
  display: block;
  width: 1.25rem; }
  .footer__social__link:hover, .footer__social__link:focus {
    color: #DBDBDB !important; }

.footer__text a {
  text-decoration: underline; }

.footer__logo {
  flex-shrink: 0;
  align-self: center;
  display: block;
  width: 13rem; }
  @media (min-width: 72em) {
    .footer__logo {
      border-right: 0.0625rem solid #797a7c;
      padding-right: 2rem; } }

.form {
  font-family: "Whitney SSm A", "Whitney SSm B", sans-serif;
  font-size: 1rem;
  font-weight: 500; }
  .form > :first-child {
    margin-top: 0; }
  .form > :last-child {
    margin-bottom: 0; }

.form__group--narrow {
  max-width: 40.625rem; }

.form__input, .form__textarea {
  display: block;
  width: 100%;
  height: 3rem;
  margin-top: 0.5rem;
  padding: 0.75rem;
  border: 0.0625rem solid #2f2f2f;
  background: #fff;
  line-height: normal;
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.3);
  border-radius: 0.1875rem; }
  .form__input::placeholder, .form__textarea::placeholder {
    color: inherit; }
  .form__input:focus, .form__textarea:focus {
    box-shadow: none; }

.form__textarea {
  height: auto;
  min-height: 5rem; }

.form__req {
  color: #FF0200; }

.form__select {
  position: relative;
  margin-top: 0.5rem;
  box-shadow: inset 0 1px 3px 0 rgba(0, 0, 0, 0.5);
  border-radius: 0.1875rem; }
  .form__select select {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
    opacity: 0; }
  .form__select option {
    font-weight: 600; }

.form__control {
  font-family: "Whitney SSm A", "Whitney SSm B", sans-serif;
  font-weight: 600;
  display: block;
  width: 100%;
  height: 3rem;
  margin-top: 0.5rem;
  padding-left: 0.75rem;
  padding-right: 3rem;
  border: 0.0625rem solid #003478;
  color: #003478;
  line-height: normal;
  font-size: 0.9375rem;
  border-radius: 0.1875rem;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
  background-image: url(data:image/svg+xml;charset=utf8,%3Csvg%20fill%3D%22%23003478%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%2020%2020%22%3E%3Cpath%20d%3D%22M10%200C4.5%200%200%204.5%200%2010s4.5%2010%2010%2010%2010-4.5%2010-10S15.5%200%2010%200zm0%2014.5L4.5%208l1.3-1.5%204.2%205%204.2-5L15.5%208%2010%2014.5z%22%2F%3E%3C%2Fsvg%3E);
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: calc(100% - 1rem) 50%;
  background-size: 1.25rem 1.25rem;
  appearance: none; }

.form__select__text {
  width: 100%;
  height: 3rem;
  padding: 1rem 3rem 1rem 0.75rem;
  border: 0.0625rem solid;
  border-radius: 0.1875rem;
  color: #000;
  font-family: "Whitney SSm A", "Whitney SSm B", sans-serif;
  line-height: 1;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  word-wrap: normal; }

.form__select__text--focus {
  outline: 0.125rem solid #4d90fe;
  outline-offset: -0.125rem; }

.form__select__arrow {
  position: absolute;
  top: 0;
  right: 0;
  width: 3rem;
  height: 3rem;
  padding: 1rem; }

.form__radio input[type="radio"] {
  position: absolute;
  border: 0;
  margin: -1px;
  padding: 0;
  width: 1px;
  height: 1px;
  overflow: hidden;
  clip: rect(0px, 0px, 0px, 0px); }

.form__radio label {
  position: relative;
  display: inline-block;
  margin-left: 2px;
  padding-left: calc(1.25rem + 10px);
  height: 1.25rem; }

.form__radio input[type="radio"] + label:before {
  position: absolute;
  top: 0;
  left: 0;
  width: 1.25rem;
  height: 1.25rem;
  overflow: hidden;
  margin: 0;
  padding: 0; }

.form__radio input[type="radio"] + label:before {
  content: '\a0';
  position: absolute;
  left: 0;
  top: 50%;
  margin-top: -0.5rem;
  height: 1.25rem;
  width: 1.25rem;
  border: 1px solid #003478;
  border-radius: 50%;
  background: #fff; }
  .module--beige .form__radio input[type="radio"] + label:before {
    background: #f5f4f0; }
  .module--gray-light .form__radio input[type="radio"] + label:before {
    background: #f5f5f5; }

.form__radio input[type="radio"]:checked + label::before {
  background: #003478;
  background-size: 150%;
  box-shadow: inset 0 0 0 0.125rem #fff; }
  .module--beige .form__radio input[type="radio"]:checked + label::before {
    box-shadow: inset 0 0 0 0.125rem #f5f4f0; }

[data-whatinput='keyboard'] .form__radio input[type="radio"]:focus + label::before {
  outline: 0.125rem solid #4d90fe;
  outline-offset: -0.125rem; }

.form__checkbox {
  margin: 0.5rem 0; }
  .form__checkbox label {
    position: relative;
    display: inline-block;
    margin-left: 2px;
    padding-left: calc(1.25rem + 10px); }
  .form__checkbox input[type="checkbox"] {
    position: absolute;
    border: 0;
    margin: -1px;
    padding: 0;
    width: 1px;
    height: 1px;
    overflow: hidden;
    clip: rect(0px, 0px, 0px, 0px); }
  .form__checkbox input[type="checkbox"] + label:before,
  .form__checkbox input[type="checkbox"] + label:after {
    position: absolute;
    top: 0;
    left: 0;
    width: 1.25rem;
    height: 1.25rem;
    overflow: hidden;
    margin: 0;
    padding: 0; }
  .form__checkbox input[type="checkbox"] + label:before {
    content: '\a0';
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    height: 1.25rem;
    width: 1.25rem;
    border: 1px solid #2f2f2f;
    box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.3);
    background-color: #fff; }
    .module--beige .form__checkbox input[type="checkbox"] + label:before {
      background-color: #f5f4f0; }
  .form__checkbox input[type="checkbox"]:checked + label::before {
    background: #003478;
    border-color: #003478; }
  .form__checkbox input[type="checkbox"]:checked + label::after {
    position: absolute;
    content: "";
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 20px;
    height: 20px;
    background-image: url(data:image/svg+xml;charset=utf8,%3Csvg%20fill%3D%22%23fff%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%2015%2013%22%3E%3Cpath%20d%3D%22M5.9%2013L0%206.5l2.2-2.1%203.6%203.9L12.7%200%2015%202.1%22%2F%3E%3C%2Fsvg%3E);
    background-size: 15px 13px;
    background-position: center center;
    background-repeat: no-repeat; }
  [data-whatinput='keyboard'] .form__checkbox input[type="checkbox"]:focus + label::before {
    outline: 0.125rem solid #4d90fe;
    outline-offset: -0.125rem; }

.grid {
  margin-top: 2.5rem;
  margin-bottom: 2.5rem; }

.grid__container {
  display: flex;
  flex-wrap: wrap;
  margin: -0.625rem; }
  .grid__container::before, .grid__container::after {
    display: table;
    width: 0;
    content: ''; }
  .grid__container::after {
    clear: both; }

.grid__container--scroll::before {
  background-image: url(data:image/svg+xml;charset=utf8,%3Csvg%20fill%3D%22%23fff%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%20100%20100%22%3E%3Cpath%20d%3D%22M39.7%2012.1c.6%200%201.2.2%201.7.7L77%2048.4c.9.9.9%202.4%200%203.4L41.4%2087.4c-.9.9-2.4.9-3.4%200s-.9-2.4%200-3.4l33.9-33.9L38%2016.2c-.9-.9-.9-2.4%200-3.4.5-.4%201.1-.7%201.7-.7z%22%2F%3E%3C%2Fsvg%3E);
  position: absolute;
  top: 50%;
  right: 1rem;
  width: 3rem;
  height: 3rem;
  transform: translateY(-50%);
  background-color: #57595B;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 2.5rem 2.5rem;
  content: '';
  z-index: 1;
  pointer-events: none; }
  @media (min-width: 48em) {
    .grid__container--scroll::before {
      display: none; } }

.grid__container--scroll::after {
  display: none;
  content: 'flickity'; }
  @media (min-width: 80em) {
    .grid__container--scroll::after {
      content: ''; } }

.grid__container--scroll.flickity-enabled {
  display: block; }

.grid__container--scroll .flickity-slider > * {
  width: 19.25rem; }
  @media (min-width: 48em) {
    .grid__container--scroll .flickity-slider > * {
      width: 22.625rem; } }

.grid__container--slider::after {
  display: none;
  content: 'flickity'; }
  @media (min-width: 64.0625em) {
    .grid__container--slider::after {
      content: ''; } }

.grid__container--slider.flickity-enabled {
  display: block;
  padding-bottom: 3rem; }

.grid__container--slider .flickity-viewport {
  position: relative;
  overflow: hidden; }

.grid__container--slider .flickity-slider {
  display: flex;
  position: static; }
  .grid__container--slider .flickity-slider > * {
    width: 100%;
    flex-shrink: 0;
    position: relative !important;
    left: 0 !important;
    float: none; }

.grid__container--scroll--scrolled::before {
  display: none; }

.grid__container--scroll--alt .flickity-slider > * {
  top: 50%;
  transform: translateY(-50%); }
  @media (min-width: 64em) {
    .grid__container--scroll--alt .flickity-slider > * {
      width: 30.625rem; } }

.grid__container--space {
  margin: -1.25rem -0.625rem; }

.grid__container--space-alt {
  margin: -0.625rem -2rem; }

.grid__item {
  width: 100%;
  padding: 0.625rem;
  float: left; }
  .grid__item > :first-child {
    margin-top: 0; }
  .grid__item > :last-child {
    margin-bottom: 0; }

.grid__item--space {
  padding: 1.25rem 0.625rem; }

.grid__item--space-alt {
  padding: 0.625rem 2rem; }

.grid__item--flex {
  display: flex; }
  .grid__item--flex > * {
    width: 100%; }

.grid__item--center {
  align-self: center; }

@media (min-width: 32em) {
  .grid__item--4 {
    width: 50%; } }

@media (min-width: 64.0625em) {
  .grid__item--4 {
    width: 25%; } }

@media (min-width: 32em) {
  .grid__item--3 {
    width: 50%; } }

@media (min-width: 64.0625em) {
  .grid__item--3 {
    width: 33.3333%; } }

@media (min-width: 64.0625em) {
  .grid__item--3--narrow {
    width: 50%; } }

@media (min-width: 90em) {
  .grid__item--3--narrow {
    width: 33.3333%; } }

@media (min-width: 32em) {
  .grid__item--3--wide {
    width: 100%; } }

@media (min-width: 48em) {
  .grid__item--3--wide {
    width: 50%; } }

@media (min-width: 80em) {
  .grid__item--3--wide {
    width: 33.3333%; } }

@media (min-width: 48em) {
  .grid__item--60 {
    width: 60%; } }

@media (min-width: 48em) {
  .grid__item--40 {
    width: 40%; } }

@media (min-width: 32em) {
  .grid__item--2 {
    width: 50%; } }

.header-container {
  position: relative;
  z-index: 4; }

.header {
  padding: 1rem 0;
  background: #003478; }
  @media (min-width: 48em) {
    .header {
      padding: 1.5rem 0; } }
  @media (min-width: 64.0625em) {
    .header {
      background: #fff;
      box-shadow: 0 0 0.375rem rgba(0, 0, 0, 0.125); } }

@media (min-width: 64.0625em) {
  .header--nav {
    padding-top: 3rem;
    padding-bottom: 3rem; } }

@media (min-width: 72em) {
  .header--nav {
    padding-top: 4rem;
    padding-bottom: 4rem; } }

@media (min-width: 76em) {
  .header--nav {
    padding-top: 5rem;
    padding-bottom: 5rem; } }

.header__logo {
  width: 16rem;
  max-width: calc(100% - 6.5rem);
  float: left;
  color: inherit;
  overflow: hidden;
  color: #fff; }
  @media (min-width: 64.0625em) {
    .header__logo {
      color: #003478; } }
  @media (min-width: 64.0625em) {
    .header--nav .header__logo {
      position: absolute;
      top: 50%;
      width: 25rem;
      max-width: calc(100% - 48rem);
      margin-top: 0;
      transform: translateY(-50%); } }
  @media (min-width: 76em) {
    .header--nav .header__logo {
      width: 25rem; } }
  @media (min-width: 64.0625em) {
    .nav-permanent .header__logo {
      display: none; } }

.header__toggle {
  width: 2.5rem;
  margin-top: 0.35rem;
  margin-left: 0.5rem;
  padding-left: 1rem;
  float: right;
  color: #fff; }
  @media (min-width: 64.0625em) {
    .header--nav .header__toggle {
      display: none; } }

.header__toggle--inactive {
  display: block; }

.header__toggle--active {
  display: none; }

.search-active .header__toggle--search .header__toggle--inactive {
  display: none; }

.search-active .header__toggle--search .header__toggle--active {
  display: block; }

.menu-active .header__toggle--menu .header__toggle--inactive {
  display: none; }

.menu-active .header__toggle--menu .header__toggle--active {
  display: block; }

.hero-container {
  position: relative;
  padding-top: .1rem; }
  .hero-container > :first-child {
    margin-top: 0; }
  .hero-container > :last-child {
    margin-bottom: 0; }
  [data-whatinput='keyboard'] .hero-container .flickity-enabled:focus {
    outline-offset: 0;
    z-index: 3; }

.hero-slider {
  margin: 0;
  padding: 0;
  list-style: none; }
  .hero-slider li {
    margin: 0; }
  .hero-slider .flickity-page-dots {
    top: 0;
    right: 0;
    width: 100%;
    margin-top: calc(46.0417% - 2rem); }
    @media (min-width: 64.0625em) {
      .hero-slider .flickity-page-dots {
        top: 50%;
        right: 4rem;
        left: auto;
        width: auto;
        margin: 0;
        transform: translateY(-50%); } }
    @media (min-width: 90em) {
      .hero-slider .flickity-page-dots {
        right: 5rem; } }
    .hero-slider .flickity-page-dots .dot {
      color: #fff;
      filter: url('data:image/svg+xml;charset=utf-8,<svg xmlns="http://www.w3.org/2000/svg"><filter id="filter"><feGaussianBlur in="SourceAlpha" stdDeviation="0" /><feOffset dx="1" dy="1" result="offsetblur" /><feFlood flood-color="rgba(0,0,0,1)" /><feComposite in2="offsetblur" operator="in" /><feMerge><feMergeNode /><feMergeNode in="SourceGraphic" /></feMerge></filter></svg>#filter');
      filter: drop-shadow(0 0.1rem 0.1rem #000);
      filter: progid:DXImageTransform.Microsoft.Shadow(color=#000,strength=0); }
      @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
        .hero-slider .flickity-page-dots .dot {
          background: rgba(0, 0, 0, 0.54); } }
      @media (min-width: 64.0625em) {
        .hero-slider .flickity-page-dots .dot {
          display: block;
          margin: 0.5rem 0; } }

.hero {
  position: relative;
  width: 100%;
  list-style: none;
  display: block; }
  .hero > :first-child {
    margin-top: 0; }
  .hero > :last-child {
    margin-bottom: 0; }

.hero--image,
.hero-slider-alt {
  padding-top: 8rem;
  padding-bottom: 1rem;
  background-size: cover;
  background-position: center; }
  @media (min-width: 64.0625em) {
    .hero--image,
    .hero-slider-alt {
      padding-top: 0;
      padding-bottom: 0;
      height: 600px;
      display: flex;
      align-items: center; } }
  .hero--image::before,
  .hero-slider-alt::before {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.45); }

.hero-slider-alt {
  padding-top: 1rem;
  padding-bottom: 8rem;
  overflow: hidden; }
  @media (min-width: 64.0625em) {
    .hero-slider-alt {
      padding-top: 0;
      padding-bottom: 0; } }
  .hero-slider-alt::before {
    z-index: 1; }
  .hero-slider-alt .flickity-slider {
    transform: none !important; }
  .hero-slider-alt .flickity-viewport {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%; }

.hero-slider-alt__image {
  position: absolute;
  top: 0;
  left: 0 !important;
  width: 100%;
  height: inherit;
  background-size: cover;
  background-position: center;
  z-index: -1;
  opacity: 0;
  transition: opacity 300ms ease; }
  .hero-slider-alt__image.is-selected {
    opacity: 1;
    z-index: 0; }

.hero-slider-alt__control {
  position: absolute;
  bottom: 1.5rem;
  right: 1.5rem;
  display: block;
  width: 2rem;
  height: 2rem;
  background-image: url(data:image/svg+xml;charset=utf8,%3Csvg%20fill%3D%22%23fff%22%20id%3D%22Layer_1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%2057%2057%22%3E%3Cstyle%3E.st0%7Bfill-rule%3Aevenodd%3Bclip-rule%3Aevenodd%3Bfill%3A%232F2F2F%3Bstroke%3A%23FFFFFF%3Bstroke-width%3A2%3B%7D%20.st1%7Bfill%3A%23FFFFFF%3B%7D%3C%2Fstyle%3E%3Cg%20id%3D%22Homepage%22%3E%3Cg%20id%3D%22Brandeis-Advancement-Concept-1%22%3E%3Cg%20id%3D%22Group-30%22%3E%3Cg%20id%3D%22Catch-Up%22%3E%3Cg%20id%3D%22Group-34%22%20transform%3D%22translate%281%201%29%22%3E%3Ccircle%20id%3D%22Oval%22%20class%3D%22st0%22%20cx%3D%2227.5%22%20cy%3D%2227.5%22%20r%3D%2227.5%22%2F%3E%3C%2Fg%3E%3C%2Fg%3E%3C%2Fg%3E%3C%2Fg%3E%3C%2Fg%3E%3Cpath%20class%3D%22st1%22%20d%3D%22M18%2016h5.1v24.9H18zM33.9%2016.1H39V41h-5.1z%22%2F%3E%3C%2Fsvg%3E);
  background-size: 2rem 2rem;
  background-position: center center;
  background-repeat: no-repeat;
  z-index: 3; }
  @media (min-width: 64.0625em) {
    .hero-slider-alt__control {
      top: auto;
      left: auto;
      transform: none;
      bottom: 4rem;
      right: 3.5rem; } }
  @media (min-width: 90em) {
    .hero-slider-alt__control {
      right: 4.5rem; } }
  .hero-slider-alt__control.paused {
    background-image: url(data:image/svg+xml;charset=utf8,%3Csvg%20fill%3D%22%23fff%22%20width%3D%2257%22%20height%3D%2257%22%20viewBox%3D%220%200%2057%2057%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cg%20transform%3D%22translate%281%201%29%22%20fill%3D%22none%22%20fill-rule%3D%22evenodd%22%3E%3Ccircle%20stroke%3D%22%23FFF%22%20stroke-width%3D%222%22%20fill%3D%22%232F2F2F%22%20cx%3D%2227.5%22%20cy%3D%2227.5%22%20r%3D%2227.5%22%2F%3E%3Cpath%20fill%3D%22%23FFF%22%20d%3D%22M20%2039.89V15l21%2012.444%22%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E); }

.hero__image {
  position: relative;
  color: #fff; }
  .hero__image img {
    width: 100%; }

.hero__gradient {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 50%;
  background-image: linear-gradient(transparent, rgba(0, 0, 0, 0.75)); }

.hero__gradient-alt {
  height: 30%; }

.hero__wrap {
  position: relative; }

.hero__content {
  margin: 0 -1rem;
  padding: 2.5rem 1rem;
  background: #f5f4f0;
  display: block; }
  .hero__content > :first-child {
    margin-top: 0; }
  .hero__content > :last-child {
    margin-bottom: 0; }
  @media (min-width: 48em) {
    .hero__content {
      margin: 0 -2rem;
      padding-right: 2rem;
      padding-left: 2rem; } }
  @media (min-width: 64.0625em) {
    .hero__content {
      position: absolute;
      bottom: 2rem;
      width: calc(66.6667% - 2rem);
      margin: 0;
      padding: 0;
      transition: opacity ease 600ms;
      background: transparent;
      color: #fff;
      opacity: 0;
      text-shadow: 0.1rem 0.1rem 0.1rem #000;
      left: 3rem; }
      .is-selected .hero__content {
        transition-delay: 300ms;
        opacity: 1; } }
  @media (min-width: 90em) {
    .hero__content {
      left: 2rem;
      bottom: 3rem;
      width: calc(50% - 3rem); } }

a.hero__content {
  display: block; }
  a.hero__content:hover, a.hero__content:focus {
    text-decoration: none; }

.hero__content--alt {
  opacity: 1; }

.hero__title, .hero__title--sized {
  position: absolute;
  bottom: 1rem;
  margin: 0;
  color: #fff;
  text-shadow: 0.1rem 0.1rem 0.1rem #000; }
  @media (min-width: 48em) {
    .hero__title, .hero__title--sized {
      bottom: 2rem; } }
  @media (min-width: 90em) {
    .hero__title, .hero__title--sized {
      bottom: 3rem; } }
  .hero__title a, .hero__title--sized a {
    color: inherit; }

@media (max-width: 48em) {
  .hero__title--sized {
    position: static;
    color: #2f2f2f;
    text-shadow: none;
    margin-top: 1rem; } }

.hero__heading {
  display: block;
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
  font-family: "Whitney SSm A", "Whitney SSm B", sans-serif;
  font-size: 2rem;
  font-weight: 500;
  line-height: 1.25; }
  @media (min-width: 64.0625em) {
    .hero__heading {
      font-size: 2.75rem;
      color: #fff; } }

.hero__text {
  display: block;
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
  font-size: 1.25rem; }
  @media (min-width: 64.0625em) {
    .hero__text {
      color: #fff; } }

@media (min-width: 64.0625em) {
  .hero__button {
    color: #fff;
    text-shadow: none; } }

.hero--video video {
  display: none; }
  @media (min-width: 48em) {
    .hero--video video {
      display: block; } }

.hero--video img {
  display: block; }
  @media (min-width: 48em) {
    .hero--video img {
      display: none; } }

.hero__video {
  display: none;
  width: 100%;
  height: 100%;
  background-color: #000;
  overflow: hidden;
  z-index: -1; }
  @media (min-width: 48em) {
    .hero__video {
      display: block; } }
  .hero__video video {
    width: 100%; }
    @supports (object-fit: cover) {
      .hero__video video {
        height: 100%;
        object-fit: cover; } }
    .hero__video video::-webkit-media-controls {
      display: none !important; }
    .hero__video video::-webkit-media-controls-start-playback-button {
      display: none !important;
      appearance: none; }

.hero__split {
  position: relative;
  display: flex;
  width: 100%;
  color: #fff; }
  .hero__split .h1-hero,
  .hero__split p {
    text-shadow: 0 0 0.25rem #000;
    color: #fff; }
  .hero__split .h1-hero {
    margin-top: 1rem; }
  .hero__split p {
    font-weight: 700; }
    .hero__split p a {
      color: #fff;
      text-decoration: underline; }
  .hero-slider-alt .hero__split {
    z-index: 1; }

@media (min-width: 64.0625em) {
  .hero__split--left {
    width: 60%;
    padding-right: 8rem; } }

.hero__split--right {
  display: none; }
  @media (min-width: 64.0625em) {
    .hero__split--right {
      display: block;
      width: 40%; } }

.image-slider-container {
  margin-top: 5rem;
  margin-bottom: 5rem;
  margin: -0.375rem;
  padding: 0.375rem;
  overflow: hidden; }
  .image-slider-container:first-child, .image-slider-container:last-child {
    margin: -0.375rem !important; }

.image-slider {
  margin: 0;
  padding: 0; }
  .image-slider .flickity-viewport {
    overflow: visible; }
    @media (min-width: 64.0625em) {
      .image-slider .flickity-viewport {
        overflow: hidden; } }
  .image-slider .flickity-page-dots {
    width: auto;
    top: 1rem;
    right: 1rem; }
    @media (min-width: 48em) {
      .image-slider .flickity-page-dots {
        top: 2.5rem;
        right: 2.5rem; } }
    @media (min-width: 64.0625em) {
      .image-slider .flickity-page-dots {
        display: none; } }
  .image-slider .flickity-prev-next-button {
    display: none; }
    @media (min-width: 64.0625em) {
      .image-slider .flickity-prev-next-button {
        display: block;
        background: rgba(255, 255, 255, 0.001);
        filter: url('data:image/svg+xml;charset=utf-8,<svg xmlns="http://www.w3.org/2000/svg"><filter id="filter"><feGaussianBlur in="SourceAlpha" stdDeviation="0" /><feOffset dx="1" dy="1" result="offsetblur" /><feFlood flood-color="rgba(0,0,0,1)" /><feComposite in2="offsetblur" operator="in" /><feMerge><feMergeNode /><feMergeNode in="SourceGraphic" /></feMerge></filter></svg>#filter');
        filter: drop-shadow(0 0.1rem 0.1rem #000);
        filter: progid:DXImageTransform.Microsoft.Shadow(color=#000,strength=0); } }
  @media screen and (min-width: 64.0625em) and (-ms-high-contrast: active), (min-width: 64.0625em) and (-ms-high-contrast: none) {
    .image-slider .flickity-prev-next-button {
      background: rgba(0, 0, 0, 0.54); } }
    @media (min-width: 64.0625em) {
        .image-slider .flickity-prev-next-button .arrow {
          fill: #fff; } }
  .image-slider [aria-hidden="true"] {
    visibility: hidden; }

@media (min-width: 64.0625em) {
  .image-slider--full .flickity-viewport {
    overflow: visible; } }

.image {
  display: block;
  position: relative;
  width: 100%;
  overflow: hidden; }
  .image:hover .image__overlay, .image:focus-within .image__overlay {
    transform: translateY(0); }
  .image:hover .image__social, .image:focus-within .image__social {
    opacity: 1; }
  .image:focus {
    outline-offset: 0; }
    .image:focus .image__overlay {
      transform: translateY(0); }
    .image:focus .image__social {
      opacity: 1; }
  .image-slider .image {
    margin: 0 0.625rem; }
    @media (min-width: 64.0625em) {
      .image-slider .image {
        width: 50%;
        margin: 0;
        box-shadow: none; } }
  .image-slider--full .image {
    margin: 0 0.625rem; }
    @media (min-width: 64.0625em) {
      .image-slider--full .image {
        width: 100%; } }

.image--social {
  position: relative;
  width: 15rem;
  max-height: 15rem; }

.image__item {
  min-height: 10rem; }
  .image--social .image__item {
    min-height: 15rem;
    object-fit: cover; }

.image__social {
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  flex-wrap: wrap;
  width: 100%;
  height: 100%;
  padding: 1rem;
  transition: opacity 300ms ease;
  background: rgba(0, 0, 0, 0.75);
  color: #fff;
  font-family: "Whitney SSm A", "Whitney SSm B", sans-serif;
  font-size: 0.75rem;
  font-weight: 500;
  opacity: 0; }
  .image--social.focus-within .image__social {
    opacity: 1; }

.image__date {
  width: 100%; }

.image__desc {
  width: 100%;
  margin: auto 0;
  max-height: 10rem;
  overflow: hidden; }
  .image__desc a {
    color: #fff; }

.image__meta {
  width: 100%;
  margin-top: auto; }
  .image__meta::before, .image__meta::after {
    display: table;
    width: 0;
    content: ''; }
  .image__meta::after {
    clear: both; }

.image__meta__link {
  margin-right: 0.5rem;
  float: left;
  color: #fff;
  overflow: hidden; }

.image__meta__icon {
  width: 0.75rem;
  margin-top: 0.1875rem;
  margin-right: 0.25rem;
  float: left; }

.image__overlay {
  padding: 1.25rem;
  background: #003478;
  color: #fff; }
  .image__overlay > :first-child {
    margin-top: 0; }
  .image__overlay > :last-child {
    margin-bottom: 0; }
  .image--social.focus-within .image__overlay {
    transform: translateY(0); }
  @media (min-width: 64.0625em) {
    .image__overlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      transform: translateY(100%) translateY(-4rem);
      transition: transform 300ms ease; } }

.image__gradient {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 50%;
  background-image: linear-gradient(transparent, rgba(0, 0, 0, 0.75)); }
  .image-slider .image__gradient {
    display: none; }
    @media (min-width: 64.0625em) {
      .image-slider .image__gradient {
        display: block; } }
  @media (min-width: 64.0625em) {
    .image-slider--full .image__gradient {
      display: none; } }

.image__heading {
  color: #fff; }

.image__heading--link {
  font-weight: 400; }
  @media (max-width: 1024px) {
    .image-slider .image__heading--link {
      color: #003478; } }
  .image:hover .image__heading--link {
    text-decoration: underline; }

.image__text {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 1.25rem;
  color: #fff;
  text-shadow: 0.1rem 0.1rem 0.1rem #000; }
  .image__text > :first-child {
    margin-top: 0; }
  .image__text > :last-child {
    margin-bottom: 0; }
  .image-slider .image__text {
    position: static;
    background: #f5f4f0;
    color: #000;
    text-shadow: none; }
    @media (min-width: 64.0625em) {
      .image-slider .image__text {
        position: absolute;
        padding: 1.25rem;
        background: transparent;
        color: #fff;
        text-shadow: 0.1rem 0.1rem 0.1rem #000; } }
  @media (min-width: 64.0625em) {
    .image-slider--full .image__text {
      position: static;
      text-shadow: none;
      color: inherit;
      background: #f5f4f0; } }

.image__box {
  background: #fff;
  padding: 1.25rem; }

body .juicer-feed {
  font-family: "Whitney SSm A", "Whitney SSm B", sans-serif;
  font-size: 0.75rem;
  font-weight: 500;
  text-align: center;
  overflow: visible; }
  body .juicer-feed a {
    transition: none; }
  body .juicer-feed h1.referral {
    display: none; }
  body .juicer-feed.modern li.feed-item {
    border-width: 1px; }
  body .juicer-feed .j-poster h3, body .juicer-feed .j-poster .feed-title {
    color: #003478; }
  body .juicer-feed .j-date {
    font-size: inherit;
    font-weight: 600;
    color: #767676; }
  body .juicer-feed .j-message a {
    color: #003478;
    font-weight: 500 !important; }
    body .juicer-feed .j-message a:hover {
      color: #003478;
      text-decoration: underline !important; }
  body .juicer-feed .j-meta a {
    color: #767676; }
    body .juicer-feed .j-meta a:hover {
      color: #003478; }
  body .juicer-feed .j-paginate.juicer-button {
    display: inline-block;
    width: auto;
    margin-bottom: 0;
    padding: 0.85rem 1.875rem;
    border: 0.0625rem solid;
    border-radius: 0.1875rem;
    color: #003478;
    font-family: "Whitney SSm A", "Whitney SSm B", sans-serif;
    font-size: 0.875rem;
    font-weight: 600;
    letter-spacing: normal;
    line-height: 1.1667;
    text-transform: uppercase;
    background-color: #fff;
    border-color: #003478;
    transition: none; }
    body .juicer-feed .j-paginate.juicer-button:hover {
      background-color: #0B7886;
      border-color: #0B7886;
      color: #fff;
      text-decoration: none; }

.media {
  margin-top: 2.5rem;
  margin-bottom: 2.5rem; }
  .media > :first-child {
    margin-top: 0; }
  .media > :last-child {
    margin-bottom: 0; }

@media (min-width: 48em) {
  .media--narrow {
    max-width: 66.6667%; } }

@media (min-width: 48em) {
  .media--left,
  .media--right {
    width: calc(50% - 1rem); } }

@media (min-width: 48em) {
  .media--left {
    margin-right: 2rem;
    float: left; } }

@media (min-width: 48em) {
  .media--right {
    margin-left: 2rem;
    float: right; } }

.media--tight {
  margin-top: -2.5rem;
  margin-bottom: -2.5rem; }
  @media (min-width: 64.0625em) {
    .media--tight {
      margin-top: 2.5rem;
      margin-bottom: 2.5rem; } }

.media__text {
  margin: 1rem 0; }
  .media__text > :first-child {
    margin-top: 0; }
  .media__text > :last-child {
    margin-bottom: 0; }

.media__text--beige {
  background: #f5f4f0;
  margin: 0;
  padding: 1.25rem; }

.media__item {
  display: inline-block;
  position: relative; }
  [data-whatinput='keyboard'] .media__item:focus {
    outline-offset: 0; }
  .media--full .media__item {
    display: block; }
  .media__item img {
    width: 100%; }

.media__overlay {
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  padding: 0 1.25rem;
  transform: translateY(-50%); }
  .media__overlay > :first-child {
    margin-top: 0; }
  .media__overlay > :last-child {
    margin-bottom: 0; }

.media__overlay--alt {
  position: absolute;
  bottom: 1.25rem;
  left: 1.25rem; }
  .media__overlay--alt > :first-child {
    margin-top: 0; }
  .media__overlay--alt > :last-child {
    margin-bottom: 0; }

.media__overlay__text {
  color: #fff;
  font-family: "Whitney SSm A", "Whitney SSm B", sans-serif;
  font-weight: 600;
  text-align: center;
  text-shadow: 0.1rem 0.1rem 0.1rem #000; }

.media__icon {
  position: absolute;
  bottom: 1.25rem;
  left: 1.25rem;
  width: 4.25rem; }
  .image-slider .media__icon {
    display: block;
    position: static;
    margin: 2rem auto auto auto;
    color: #2f2f2f; }

.media__credit {
  margin-top: -0.75rem; }

.media__embed {
  position: relative;
  padding-bottom: 56.25%; }
  .media__embed > * {
    position: absolute;
    width: 100%;
    height: 100%; }

.jw-skin-bekle.jwplayer:not(.jw-flag-touch):not(.jw-error):not(.jw-state-error):not(.jw-state-buffering) .jw-display-icon-container:focus {
  background-color: #139ed5 !important; }

.jw-skin-bekle:not(.jw-flag-touch) .jw-button-color:hover, .jw-skin-bekle .jw-button-color:focus {
  background-color: #139ed5 !important; }

.media__container__link {
  display: block; }
  [data-whatinput='keyboard'] .media__container__link:focus {
    outline-offset: 0; }
  .media__container__link .media__item {
    overflow: hidden; }
    .media__container__link .media__item img {
      transition: transform 300ms ease; }
  .media__container__link:hover {
    color: #003478; }
    .media__container__link:hover .h3 span,
    .media__container__link:hover .h4 span,
    .media__container__link:hover .faculty__listing__name span,
    .media__container__link:hover .h5 span {
      color: #003478; }
    .media__container__link:hover img {
      transform: scale(1.1); }
  .media__container__link .h3 span,
  .media__container__link .h4 span,
  .media__container__link .faculty__listing__name span,
  .media__container__link .h5 span,
  .media__container__link .h6 span {
    color: #003478; }

.mega {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
  background: #f5f4f0;
  color: #000;
  box-shadow: 0 0 0.375rem rgba(0, 0, 0, 0.1);
  visibility: hidden;
  z-index: -1; }
  .nav__item--active .mega {
    visibility: visible; }

.mega__menu {
  width: calc(55% - 0.625rem);
  float: left; }

.mega__content {
  width: calc(45% - 0.625rem);
  float: right; }
  .mega__content > :first-child {
    margin-top: 0; }
  .mega__content > :last-child {
    margin-bottom: 0; }

.mega__heading {
  margin-bottom: 1.25rem;
  border-bottom: 0.25rem solid #003478;
  color: #003478;
  font-family: "Whitney SSm A", "Whitney SSm B", sans-serif;
  font-size: 2.25rem;
  font-weight: 400; }

.mega__heading--small {
  padding-top: 0.875rem;
  padding-bottom: 0.375rem;
  font-size: 1.5rem; }

.mega__nav {
  width: 33.333%;
  margin: 0;
  padding: 0 1.25rem 0 0;
  float: left;
  font-family: "Whitney SSm A", "Whitney SSm B", sans-serif;
  font-size: 1rem;
  line-height: 1.25;
  list-style: none; }

.mega__nav__item {
  margin-bottom: 1.25rem; }

.mega__nav__link {
  font-weight: 600; }
  .mega__nav__item--current > .mega__nav__link {
    color: #000;
    text-decoration: none; }

.mega__close {
  position: relative;
  cursor: pointer; }

.mega__close--icon {
  position: absolute;
  right: 0;
  width: 1.5rem; }

.menu-container {
  position: relative;
  max-width: 90rem;
  margin: auto; }

.menu {
  position: absolute;
  left: -999rem;
  width: 100%;
  padding: 2.5rem 1rem;
  background: #f5f4f0;
  font-family: "Whitney SSm A", "Whitney SSm B", sans-serif;
  font-size: 1rem;
  line-height: 1.125;
  box-shadow: 0 0 0.375rem rgba(0, 0, 0, 0.1);
  visibility: hidden;
  z-index: 4; }
  .menu > :first-child {
    margin-top: 0; }
  .menu > :last-child {
    margin-bottom: 0; }
  @media (min-width: 48em) {
    .menu {
      padding-right: 2rem;
      padding-left: 2rem; } }
  @media (min-width: 64.0625em) {
    .menu {
      width: 21.25rem;
      padding-right: 1rem;
      padding-left: 1rem; } }
  .menu-active .menu {
    position: static;
    left: auto;
    position: absolute;
    visibility: visible; }
    @media (min-width: 64.0625em) {
      .menu-active .menu {
        right: 2rem; } }
    @media (min-width: 90em) {
      .menu-active .menu {
        right: 3rem; } }

@media (min-width: 64.0625em) {
  .menu--small {
    display: none; } }

.menu--large {
  display: none; }
  @media (min-width: 64.0625em) {
    .menu--large {
      display: block; } }

.menu__list {
  margin-top: 2.5rem;
  margin-bottom: 2.5rem;
  padding: 0;
  list-style: none; }

.menu__list--primary {
  text-transform: uppercase; }

.menu__list--sub {
  position: absolute;
  left: -999rem;
  margin: 1.25rem 0 0;
  font-weight: 500;
  text-transform: none;
  visibility: hidden; }
  .menu__item--active > .menu__list--sub {
    position: static;
    left: auto;
    visibility: visible; }

.menu__item {
  margin-top: 1.25rem;
  margin-bottom: 1.25rem; }
  .menu__item .button:hover {
    background-color: #003478;
    color: #fff;
    border-color: #003478; }

.menu__link {
  display: inline-block;
  max-width: calc(100% - 2rem);
  font-weight: 600; }
  .menu__item--current > .menu__link {
    font-weight: 600; }
    .menu__item--current > .menu__link:hover {
      text-decoration: none; }

.menu__link--null {
  color: #003478; }

.menu__link--sub {
  padding-left: 0.75rem;
  font-weight: 400; }

.menu__link--sub-sub {
  padding-left: 1.5rem;
  font-weight: 400; }

.menu__sub-toggle {
  width: 1.125rem;
  float: right; }

.menu__sub-toggle__icon--inactive {
  display: block; }
  .menu__item--active > .menu__sub-toggle > span > .menu__sub-toggle__icon--inactive {
    display: none; }

.menu__sub-toggle__icon--active {
  display: none; }
  .menu__item--active > .menu__sub-toggle > span > .menu__sub-toggle__icon--active {
    display: block; }

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }

.modal__overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.75); }

.modal__container {
  position: relative;
  top: 50%;
  transform: translateY(-50%); }

.modal__status {
  text-align: center; }

.modal__item {
  display: block;
  max-width: 100%;
  margin-right: auto;
  margin-left: auto; }

.modal__video {
  position: relative;
  padding-bottom: 56.25%;
  background: #000; }

.modal__embed {
  position: absolute;
  width: 100%;
  height: 100%; }

.modal__nav {
  position: absolute;
  top: 50%;
  transform: translateY(-50%); }

.modal__nav--previous {
  left: 0; }

.modal__nav--next {
  right: 0; }

.modal__close {
  position: absolute;
  top: 0;
  right: 0; }

.modal__caption {
  display: block;
  color: #fff;
  margin-top: 1rem;
  font-size: 1.25rem; }

.modal {
  z-index: 5; }

.modal__container {
  max-width: 90rem;
  margin: auto;
  padding: 3rem 1rem; }
  @media (min-width: 48em) {
    .modal__container {
      padding: 3rem 2rem; } }
  @media (min-width: 90em) {
    .modal__container {
      padding: 3rem; } }

.modal__overlay,
.modal__status,
.modal__controls,
.modal__close {
  transition: opacity 320ms ease;
  opacity: 0; }

.modal--active .modal__overlay,
.modal--active .modal__controls,
.modal--active .modal__close,
.modal--loading .modal__status,
.modal--loaded .modal__status {
  opacity: 1; }

.modal__item {
  transform: scale(0.75);
  transition: opacity 320ms ease, transform 320ms ease;
  opacity: 0; }

.modal--loaded .modal__item {
  transform: scale(1);
  opacity: 1; }

.modal__close {
  background-image: url(data:image/svg+xml;charset=utf8,%3Csvg%20fill%3D%22%23fff%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%2024%2024%22%3E%3Cpath%20d%3D%22M0%2022.9L22.9.1%2024%201.2%201.1%2024%22%2F%3E%3Cpath%20d%3D%22M0%201.1L1.1%200%2024%2022.8l-1.1%201.1%22%2F%3E%3C%2Fsvg%3E);
  width: 3rem;
  height: 3rem;
  background-position: center;
  background-size: 1rem 1rem;
  text-indent: -999rem;
  overflow: hidden; }

.modal__nav--icon {
  display: block;
  width: 3rem;
  color: #fff;
  filter: url('data:image/svg+xml;charset=utf-8,<svg xmlns="http://www.w3.org/2000/svg"><filter id="filter"><feGaussianBlur in="SourceAlpha" stdDeviation="0" /><feOffset dx="1" dy="1" result="offsetblur" /><feFlood flood-color="rgba(0,0,0,1)" /><feComposite in2="offsetblur" operator="in" /><feMerge><feMergeNode /><feMergeNode in="SourceGraphic" /></feMerge></filter></svg>#filter');
  filter: drop-shadow(0 0.1rem 0.1rem #000);
  filter: progid:DXImageTransform.Microsoft.Shadow(color=#000,strength=0); }
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .modal__nav--icon {
      background: rgba(0, 0, 0, 0.54); } }

.module {
  padding-top: 5rem;
  padding-bottom: 5rem; }
  .module > :first-child {
    margin-top: 0; }
  .module > :last-child {
    margin-bottom: 0; }
  .module.triangle-tab {
    padding-top: 6.25rem; }

.module--tight {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem; }
  @media (min-width: 64.0625em) {
    .module--tight {
      padding-top: 5rem;
      padding-bottom: 5rem; } }
  .module--tight.triangle-tab {
    padding-top: 3.75rem; }
    @media (min-width: 64.0625em) {
      .module--tight.triangle-tab {
        padding-top: 6.25rem; } }

.module--tight-alt {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem; }
  @media (min-width: 64.0625em) {
    .module--tight-alt {
      padding-top: 3.25rem;
      padding-bottom: 3.25rem; } }

.module--margin {
  margin-top: 5rem;
  margin-bottom: 5rem; }

.module--beige {
  background: #f5f4f0; }

.module--gray-light {
  background: #f5f5f5; }

.module--gray-dark {
  background: #57595B;
  color: #fff; }
  .module--gray-dark .module__title {
    color: #fff; }

.module--blue {
  background: #003478;
  color: #fff; }
  .module--blue .module__title {
    color: #fff; }

.module--shadow {
  position: relative;
  box-shadow: 0 0 0.375rem rgba(0, 0, 0, 0.1);
  z-index: 1; }

.module--relative {
  position: relative; }

.module__container > :first-child {
  margin-top: 0; }

.module__container > :last-child {
  margin-bottom: 0; }

.module__container p:nth-of-type(3).p--sans-serif, .module__container p.caption:nth-of-type(3), .module__container p.caption--alt:nth-of-type(3) {
  margin-top: -1rem; }

.module__container__link {
  display: block; }
  .module__container__link:focus {
    outline-offset: 0; }

.module__title {
  margin-bottom: 2.5rem; }

.module__subtitle {
  margin-top: -2rem;
  margin-bottom: 2.5rem;
  font-size: 1.25rem;
  text-align: center; }

.module--overflow-hide {
  overflow: hidden; }

@media (min-width: 64.0625em) {
  .nav-permanent {
    margin-top: 3.75rem; } }

.nav-container {
  display: none; }
  @media (min-width: 64.0625em) {
    .nav-container {
      display: block;
      float: right; } }

.nav-placeholder {
  display: none;
  height: 2.5625rem; }
  .nav-fixed .nav-placeholder {
    display: block; }
  .nav-permanent .nav-placeholder {
    display: none; }

.nav-fixed .nav {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background: #003478;
  color: #fff; }

.nav-fixed .nav__wrap {
  max-width: 90rem;
  margin-right: auto;
  margin-left: auto;
  padding-top: 0.5625rem;
  padding-right: 2rem;
  padding-bottom: 0.5625rem;
  padding-left: 2rem; }
  .nav-fixed .nav__wrap::before, .nav-fixed .nav__wrap::after {
    display: table;
    width: 0;
    content: ''; }
  .nav-fixed .nav__wrap::after {
    clear: both; }
  @media (min-width: 90em) {
    .nav-fixed .nav__wrap {
      padding-right: 3rem;
      padding-left: 3rem; } }

.nav__logo {
  display: none;
  width: 20rem;
  margin: 0.6875rem 0;
  float: left;
  color: #fff; }
  @media (min-width: 64.0625em) {
    .nav__logo {
      max-width: calc(100% - 49.8125rem); } }
  @media (min-width: 72em) {
    .nav__logo {
      max-width: calc(100% - 56rem); } }
  @media (min-width: 76em) {
    .nav__logo {
      max-width: calc(100% - 58.0625rem); } }
  .nav-fixed .nav__logo {
    display: block; }

.nav__group {
  float: right;
  margin-top: 0.75rem; }
  .nav-fixed .nav__group {
    margin-top: 0.25rem; }
    @media (min-width: 80em) {
      .nav-fixed .nav__group {
        margin-top: 0.75rem; } }

.nav__search-toggle {
  display: none;
  width: 1rem;
  margin-left: 1.5rem;
  padding: 0.8125rem 0;
  float: right;
  color: inherit; }
  @media (min-width: 72em) {
    .nav__search-toggle {
      margin-left: 2rem; } }
  .nav-fixed .nav__search-toggle {
    display: block; }

.nav__search-toggle__inactive {
  display: block; }
  .nav-search-active .nav__search-toggle__inactive {
    display: none; }

.nav__search-toggle__active {
  display: none; }
  .nav-search-active .nav__search-toggle__active {
    display: block; }

.nav__list {
  margin: 0;
  padding: 0;
  float: right;
  list-style: none; }
  .nav__list::before, .nav__list::after {
    display: table;
    width: 0;
    content: ''; }
  .nav__list::after {
    clear: both; }

.nav__item {
  margin: 0 0 0 1.5rem;
  float: left; }
  @media (min-width: 72em) {
    .nav__item {
      margin: 0 0 0 2rem; } }

.nav__item--button {
  display: none; }
  .nav-fixed .nav__item--button {
    display: block; }

.nav__item--active::before {
  height: 3rem;
  background: rgba(255, 255, 255, 0.001);
  content: ''; }
  @media (min-width: 72em) {
    .nav__item--active::before {
      height: 4rem; } }
  @media (min-width: 76em) {
    .nav__item--active::before {
      height: 5rem; } }
  .nav-fixed .nav__item--active::before {
    height: 0.5rem; }

.nav__link {
  display: block;
  position: relative;
  padding: 0.5625rem 0;
  color: inherit;
  font-family: "Whitney SSm A", "Whitney SSm B", sans-serif;
  font-weight: 600;
  font-size: 0.85rem;
  line-height: 1.95;
  text-transform: uppercase; }
  @media (min-width: 72em) {
    .nav__link {
      font-size: 0.9125rem;
      line-height: 1.8; } }
  @media (min-width: 76em) {
    .nav__link {
      font-size: 0.975rem;
      line-height: 1.7; } }
  .nav__link::before {
    display: none;
    position: absolute;
    bottom: 0.125rem;
    left: 0;
    width: 100%;
    height: 0.25rem;
    background: #003478;
    content: ''; }
    .nav-fixed .nav__link::before {
      background: #fff; }
  .nav__link:hover {
    text-decoration: none; }
    .nav__link:hover::before {
      display: block; }
  .nav-permanent .nav__link {
    text-transform: none;
    font-size: 0.8625rem;
    font-weight: 500; }
    @media (min-width: 72em) {
      .nav-permanent .nav__link {
        font-size: 0.925rem; } }
    @media (min-width: 76em) {
      .nav-permanent .nav__link {
        font-size: 0.9875rem; } }
    @media (min-width: 90em) {
      .nav-permanent .nav__link {
        font-size: 1.05rem;
        line-height: 1.4375; } }
  .nav__item--current > .nav__link::before {
    display: block; }

.nav__link--arrow {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px; }
  .nav__link--arrow > span {
    position: absolute;
    color: #000;
    background-color: #fff;
    left: 50%;
    top: 20px;
    transform: translateX(-50%);
    width: 1.5rem;
    outline: 0.125rem solid #4d90fe;
    outline-offset: -0.125rem; }
    .nav__link--arrow > span span {
      width: 1.15rem;
      left: 50%;
      transform: translateX(-50%); }

.nav__link--arrow:focus {
  outline: 0;
  display: block;
  clip: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  padding: 0;
  width: auto;
  height: 20px;
  line-height: 20px;
  margin-top: -20px;
  position: relative; }

.nav__button {
  text-transform: none;
  padding: 0.35rem 1rem;
  line-height: 1.7;
  margin-top: 0.25rem; }

.notice-group {
  margin-top: 2.5rem;
  margin-bottom: 2.5rem; }

.notice {
  padding: 1.25rem; }
  .notice > :first-child {
    margin-top: 0; }
  .notice > :last-child {
    margin-bottom: 0; }
  .notice:nth-child(odd) {
    background: #f5f4f0; }

.notice__label {
  text-transform: uppercase;
  font-weight: 600; }

.overlay {
  position: relative; }

.overlay--image {
  background-size: cover;
  overflow: hidden;
  background-position: center; }
  .overlay--image::before {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.33); }

.overlay--video {
  width: 100%;
  height: auto;
  overflow: hidden; }
  @media (min-width: 48em) {
    .overlay--video {
      height: 600px; } }

.overlay__image, .overlay__video, .overlay__video__media {
  width: 100%; }

.overlay__image {
  position: static; }

.overlay__video {
  width: 100%;
  max-height: 768px; }

.overlay__wrap > :first-child {
  margin-top: 0; }

.overlay__wrap > :last-child {
  margin-bottom: 0; }

.overlay__container {
  margin: 0 -1rem;
  padding: 2.5rem 1rem;
  background: #f5f4f0; }
  .overlay__container > :first-child {
    margin-top: 0; }
  .overlay__container > :last-child {
    margin-bottom: 0; }
  .overlay--image .overlay__container {
    position: relative;
    transform: none;
    background: transparent;
    width: 100%;
    max-width: 100%;
    margin: 0;
    padding: 0; }
  @media (min-width: 48em) {
    .overlay__container {
      position: absolute;
      top: 50%;
      width: 100%;
      max-width: calc(100% - 4rem);
      margin: 0;
      padding: 0;
      transform: translateY(-50%);
      background: transparent;
      color: #fff; } }
  @media (min-width: 90em) {
    .overlay__container {
      width: 84rem;
      max-width: calc(100% - 6rem); } }

.overlay__title {
  color: #fff;
  text-shadow: 0.1rem 0.1rem 0.1rem #000; }

.overlay__text {
  font-family: "Whitney SSm A", "Whitney SSm B", sans-serif;
  font-size: 1.25rem; }
  @media (min-width: 48em) {
    .overlay__text {
      font-size: 1.75rem;
      text-shadow: 0.1rem 0.1rem 0.1rem #000; } }

@media (min-width: 48em) {
  .overlay__button {
    border-color: #fff;
    background: #fff; } }

.nav-fixed.nav-search-active .search--nav {
  position: static;
  left: auto;
  visibility: visible; }

.search {
  position: absolute;
  left: -999rem;
  padding: 1rem 0;
  background: #f5f4f0;
  color: #000;
  visibility: hidden; }
  @media (min-width: 48em) {
    .search {
      padding: 2rem 0; } }
  .search-active .search {
    position: static;
    left: auto;
    visibility: visible; }

.search-active .search--nav {
  position: absolute;
  left: -999rem;
  visibility: hidden; }

.search__form {
  position: relative; }
  .search__form::before, .search__form::after {
    display: table;
    width: 0;
    content: ''; }
  .search__form::after {
    clear: both; }
  .search__form legend {
    position: absolute;
    left: -999rem; }

.search__input {
  display: block;
  width: 100%;
  height: 3rem;
  padding: 0.75rem 3rem 0.75rem 0.75rem;
  border: 0.0625rem solid #000;
  background: #fff;
  font-family: "Whitney SSm A", "Whitney SSm B", sans-serif;
  font-size: 1rem;
  font-weight: 500;
  line-height: normal; }

.search__button {
  position: absolute;
  top: 0;
  right: 0;
  width: 3rem;
  padding: 0.6875rem;
  overflow: hidden; }

.search__option {
  margin-top: 1rem;
  margin-right: 2.5rem;
  float: left;
  line-height: 1; }

.search__radio {
  margin-right: 0.25rem;
  vertical-align: middle; }

.search__label {
  font-family: "Whitney SSm A", "Whitney SSm B", sans-serif;
  font-size: 0.75rem;
  text-transform: uppercase;
  font-weight: 600; }

.story-slider-container,
.story-shift-container {
  margin-top: 5rem;
  margin-bottom: 5rem;
  margin: -0.375rem;
  padding: 0.375rem;
  overflow: hidden; }
  .story-slider-container:first-child, .story-slider-container:last-child,
  .story-shift-container:first-child,
  .story-shift-container:last-child {
    margin: -0.375rem; }

.story-slider,
.story-shift {
  margin: 0;
  padding: 0;
  list-style: none; }
  .story-slider .flickity-viewport,
  .story-shift .flickity-viewport {
    overflow: visible; }

.story {
  width: 100%;
  margin: 0 0.625rem;
  background: #fff;
  box-shadow: 0 0 0.125rem 0.125rem rgba(0, 0, 0, 0.1); }
  .story::before, .story::after {
    display: table;
    width: 0;
    content: ''; }
  .story::after {
    clear: both; }
  @media (min-width: 48em) {
    .story {
      display: flex; } }

@media (min-width: 48em) {
  .story__media {
    position: relative;
    width: 50%;
    float: left; } }

@media (min-width: 64.0625em) {
  .story__media {
    width: 66.6667%; } }

@media (min-width: 48em) {
  .story__media::before {
    display: block;
    padding-bottom: 66.6667%;
    content: ''; } }

@media (min-width: 48em) {
  .story__image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%; }
    @supports (object-fit: cover) {
      .story__image {
        height: 100%;
        object-fit: cover; } } }

.story__text {
  position: relative;
  padding: 2rem 1rem; }
  .story__text > :first-child {
    margin-top: 0; }
  .story__text > :last-child {
    margin-bottom: 0; }
  @media (min-width: 48em) {
    .story__text {
      width: 50%;
      padding: 6.5rem 2.5rem 5rem;
      float: right; } }
  @media (min-width: 64.0625em) {
    .story__text {
      width: 33.3333%; } }

.story__type {
  position: absolute;
  top: 0;
  left: 1rem;
  padding: 0.25rem 0.5rem;
  background: #003478;
  color: #fff;
  font-family: "Whitney SSm A", "Whitney SSm B", sans-serif;
  font-size: 0.75rem;
  font-weight: 500; }
  @media (min-width: 48em) {
    .story__type {
      left: 2.5rem; } }
  @media (min-width: 48em) {
    .story__type + * {
      margin-top: 0; } }

.story-shift-container:first-child {
  margin-top: 2rem; }

@media (min-width: 64.0625em) {
  .story-shift-container {
    position: relative;
    margin: 0;
    padding: 0;
    overflow: visible; }
    .story-shift-container:first-child, .story-shift-container:last-child {
      margin: 2rem 3rem; } }

.story-shift-viewport {
  margin: -0.375rem;
  padding: 0.375rem; }

.story-shift-nav {
  display: none; }
  @media (min-width: 64.0625em) {
    .story-shift-nav {
      display: block;
      position: absolute;
      top: 50%;
      width: 4.125rem;
      height: 4.125rem;
      border-radius: 50%;
      background-color: #003478;
      transform: translateY(-50%);
      color: #fff; } }
  .story-shift-nav svg {
    position: absolute;
    left: 20%;
    top: 20%;
    width: 60%;
    height: 60%; }
  .story-shift-nav:hover {
    background-color: #0B7886; }

.story-shift-nav--prev {
  right: calc(100% + 1rem); }

.story-shift-nav--next {
  left: calc(100% + 1rem); }

.story-shift-nav--disabled {
  visibility: hidden; }

.story-shift__item {
  width: 100%;
  margin: 0 0.625rem 0; }
  @media (min-width: 64.0625em) {
    .story-shift__item {
      display: inline-block;
      width: auto;
      margin: 0 1.25rem 0 0;
      font-size: 1.0625rem;
      white-space: normal;
      vertical-align: top;
      word-wrap: break-word; }
      .story-shift__item:hover .story {
        box-shadow: 0 19px 12px rgba(0, 0, 0, 0.3), 0 15px 12px rgba(3, 3, 3, 0.22); } }
  .story-shift__item .story {
    margin: 0; }
    @media (min-width: 64.0625em) {
      .story-shift__item .story {
        height: 100%;
        transition: box-shadow 300ms ease; } }

@media (min-width: 64.0625em) {
  .story-shift {
    transition: transform ease 600ms;
    font-size: 0;
    white-space: nowrap;
    word-wrap: normal;
    display: flex; }
    .story-shift .story__media {
      width: 0;
      min-height: 21.25rem;
      transition: width ease 600ms, opacity ease 600ms;
      opacity: 0;
      overflow: hidden; }
    .story-shift .story__image {
      width: 31.875rem;
      max-width: none; }
    .story-shift .story__text {
      width: 21rem;
      padding: 5rem 2.5rem 5rem; } }

.story-shift::after {
  display: none;
  content: 'flickity'; }
  @media (min-width: 64.0625em) {
    .story-shift::after {
      content: ''; } }

.story-shift.flickity-enabled {
  transform: none !important;
  padding-bottom: 3rem; }

@media (min-width: 64.0625em) {
  .story-shift__item--active .story__media {
    width: 31.875rem;
    opacity: 1; } }

.subnav {
  margin-top: 2.5rem;
  margin-bottom: 2.5rem;
  display: none;
  padding: 1rem 0 0;
  border-top: 0.25rem solid #57595B;
  font-family: "Whitney SSm A", "Whitney SSm B", sans-serif;
  font-size: 1rem;
  line-height: 1.25;
  list-style: none;
  font-weight: 600; }
  @media (min-width: 64.0625em) {
    .subnav {
      display: block; } }

.subnav--sub {
  position: relative;
  margin: 1rem 0 0;
  padding: 0;
  border-top: 0;
  font-weight: 400; }
  .subnav--sub::before {
    position: absolute;
    width: 0.25rem;
    height: 100%;
    background: #f5f5f5;
    content: ''; }

.subnav__item {
  margin: 1rem 0 0; }

.subnav__link {
  display: inline-block;
  position: relative;
  font-weight: 600; }
  .subnav__item--current > .subnav__link {
    color: #000;
    font-weight: 600; }
    .subnav__item--current > .subnav__link:hover {
      text-decoration: none; }

.subnav__link--sub,
.subnav__link--sub-sub {
  padding-left: 1rem;
  font-weight: 400; }
  .subnav__item--current > .subnav__link--sub::before, .subnav__item--current >
  .subnav__link--sub-sub::before {
    position: absolute;
    left: 0;
    width: 0.25rem;
    height: 100%;
    background: #bcbcbc;
    content: ''; }
  .subnav__item--current-alt > .subnav__link--sub::before, .subnav__item--current-alt >
  .subnav__link--sub-sub::before {
    position: absolute;
    left: 0;
    width: 0.25rem;
    height: 100%;
    background: #bcbcbc;
    content: ''; }

.subnav__link--sub-sub {
  padding-left: 1.75rem; }

.subnav__sub-toggle {
  width: 1.125rem;
  float: right; }

.subnav__sub-toggle__icon--inactive {
  display: block; }
  .subnav__item--active > .subnav__sub-toggle > span > .subnav__sub-toggle__icon--inactive {
    display: none; }

.subnav__sub-toggle__icon--active {
  display: none; }
  .subnav__item--active > .subnav__sub-toggle > span > .subnav__sub-toggle__icon--active {
    display: block; }

.table {
  margin-top: 2.5rem;
  margin-bottom: 2.5rem;
  position: relative; }
  .table::after {
    background-image: url(data:image/svg+xml;charset=utf8,%3Csvg%20fill%3D%22%23fff%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%20100%20100%22%3E%3Cpath%20d%3D%22M39.7%2012.1c.6%200%201.2.2%201.7.7L77%2048.4c.9.9.9%202.4%200%203.4L41.4%2087.4c-.9.9-2.4.9-3.4%200s-.9-2.4%200-3.4l33.9-33.9L38%2016.2c-.9-.9-.9-2.4%200-3.4.5-.4%201.1-.7%201.7-.7z%22%2F%3E%3C%2Fsvg%3E);
    position: absolute;
    top: 50%;
    right: 1rem;
    width: 3rem;
    height: 3rem;
    transform: translateY(-50%);
    background-color: #57595B;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 2.5rem 2.5rem;
    content: '';
    pointer-events: none; }
    @media (min-width: 48em) {
      .table::after {
        display: none; } }

.table--scrolled::after {
  display: none; }

.table--fixed::after {
  display: none; }

.table__scroll {
  overflow: auto; }

table {
  width: 100%;
  table-layout: fixed;
  border-collapse: separate; }
  .table__scroll table {
    min-width: 48rem; }
    @media (min-width: 48em) {
      .table__scroll table {
        min-width: 0; } }

tr:nth-child(even) {
  background: #f5f4f0; }

tbody tr:nth-child(odd) td {
  box-shadow: inset 0.125rem 0 0 #f5f4f0; }
  tbody tr:nth-child(odd) td:first-child {
    box-shadow: none; }

th,
td {
  padding: 0.75rem 1.25rem;
  box-shadow: inset 0.125rem 0 0 #fff;
  vertical-align: top; }
  th > :first-child,
  td > :first-child {
    margin-top: 0; }
  th > :last-child,
  td > :last-child {
    margin-bottom: 0; }
  th:first-child,
  td:first-child {
    border-left: 0; }

td {
  font-size: 0.875rem; }
  td a:not([class]) {
    font-weight: 600;
    border-bottom: 1px solid #003478;
    padding-bottom: .125em; }
    td a:not([class]):hover {
      border-color: #0B7886;
      text-decoration: none;
      color: #0B7886; }

th {
  background-color: #003478;
  color: #fff;
  font-family: "Whitney SSm A", "Whitney SSm B", sans-serif;
  font-size: 1.25rem;
  text-align: left;
  font-weight: 600; }

.tab-accordions .tabs {
  display: none; }
  @media (min-width: 64.0625em) {
    .tab-accordions .tabs {
      display: block; } }

.tab-accordions .accordion {
  display: block; }
  @media (min-width: 64.0625em) {
    .tab-accordions .accordion {
      display: none; } }

.tabs {
  margin: 3rem 0; }

[role="tablist"] {
  margin: 0 0 2rem;
  overflow: visible;
  display: flex;
  justify-content: space-between; }

[role="tab"] {
  position: relative;
  margin: 0;
  padding: 0.75rem 0.5rem;
  font-family: "Whitney SSm A", "Whitney SSm B", sans-serif;
  border: 0.0625rem solid #003478;
  background-color: #fff;
  border-radius: 0.1875rem;
  font-size: 0.875rem;
  line-height: 1;
  font-weight: 600;
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 0;
  margin-left: 1rem;
  flex-grow: 1; }
  [role="tab"]:first-child {
    margin-left: 0; }

@media (min-width: 64.0625em) {
  [role="tab"][aria-selected="true"]::before {
    border-width: 0.5625rem 0.5625rem 0 0.5625rem;
    border-color: #003478 transparent transparent transparent;
    position: absolute;
    bottom: -0.5625rem;
    left: 50%;
    transform: translateX(-50%);
    display: block;
    content: ''; } }

[role="tab"][aria-selected="true"] {
  background-color: #003478;
  color: #fff; }

[role="tab"]:hover,
[role="tab"]:focus,
[role="tab"]:active {
  background-color: #003478;
  color: #fff; }
  [data-whatinput='keyboard'] [role="tab"]:hover, [data-whatinput='keyboard']
  [role="tab"]:focus, [data-whatinput='keyboard']
  [role="tab"]:active {
    outline: 0.125rem solid #4d90fe;
    outline-offset: -0.125rem;
    outline-offset: 0.125rem; }

[role="tabpanel"] {
  position: relative;
  z-index: 2; }
  @media (min-width: 64.0625em) {
    [role="tabpanel"] {
      border: 0.0625rem solid #797a7c; } }

[data-whatinput='keyboard'] [role="tabpanel"]:focus {
  outline: 0.125rem solid #4d90fe;
  outline-offset: -0.125rem;
  outline-offset: 0.125rem; }

@media (min-width: 64.0625em) {
  .tab__content {
    display: flex; } }

@media (min-width: 64.0625em) {
  .tab__content__image {
    width: 60%;
    flex-shrink: 0; } }

.tab__content__text {
  padding: 1rem;
  background-color: #fff; }
  .tab__content__text > :first-child {
    margin-top: 0; }
  .tab__content__text > :last-child {
    margin-bottom: 0; }
  @media (min-width: 64.0625em) {
    .tab__content__text {
      padding-left: 3rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 100%; } }

.title {
  margin-top: 2.5rem;
  margin-bottom: 2.5rem;
  text-align: center; }
  @media (min-width: 48em) {
    .title {
      display: flex;
      align-items: center;
      justify-content: center; }
      .title::before, .title::after {
        display: table;
        width: 0;
        content: ''; }
      .title::after {
        clear: both; } }
  @media (min-width: 64.0625em) {
    .title {
      margin-top: 5rem;
      margin-bottom: 5rem; } }

@media (min-width: 64.0625em) {
  .title + .story-slider-container,
  .title + .image-slider-container {
    margin-top: -2.5rem !important; } }

.title__logo {
  width: 3rem;
  margin: 0 auto 1.25rem; }
  @media (min-width: 48em) {
    .title__logo {
      width: 4rem;
      margin: 0 1.25rem 0 0;
      float: left; } }

.title__text {
  margin: 0; }
  @media (min-width: 48em) {
    .title__text {
      max-width: calc(100% - 5.25rem);
      margin: 0;
      float: left; } }
  .title__text a {
    color: inherit; }

.title__text--alt {
  text-align: center; }
  .title__text--alt h1 {
    margin: 0 0 .25rem 0; }

.toggle__form legend {
  font-family: "Whitney SSm A", "Whitney SSm B", sans-serif;
  font-size: 0.9375rem;
  font-weight: 700;
  margin-bottom: 1rem; }

.toggle__form .form__radio {
  display: inline-block;
  margin-right: 1rem;
  margin-bottom: 1rem; }

.toggle__form label {
  font-family: "Whitney SSm A", "Whitney SSm B", sans-serif;
  font-size: 0.8125rem;
  font-weight: 700;
  color: #003478;
  text-transform: uppercase; }

.toggle__form__dropdown {
  max-width: 400px; }

.toggle__form__dropdown[data-toggle] {
  display: none; }
  .toggle__form__dropdown[data-toggle].active {
    display: block; }

.topbar {
  display: none;
  padding: 0.625rem 0 0.5625rem;
  background: #003478;
  color: #fff; }
  @media (min-width: 64.0625em) {
    .topbar {
      display: block; } }

.topbar__list {
  margin: 0;
  padding: 0;
  float: right;
  list-style: none; }

.topbar__list__item {
  margin: 0 0 0 1.5rem;
  float: left; }

.topbar__list__link {
  display: block;
  position: relative;
  padding: 0.25rem 1.5rem;
  color: inherit;
  font-family: "Whitney SSm A", "Whitney SSm B", sans-serif;
  font-size: 0.875rem;
  font-weight: 700;
  border: 0.0625rem solid #fff;
  border-radius: 0.1875rem;
  margin-top: 0.35rem; }
  .topbar__list__link:hover, .topbar__list__link:focus {
    text-decoration: none;
    background-color: #fff;
    color: #003478; }
    [data-whatinput='keyboard'] .topbar__list__link:hover, [data-whatinput='keyboard'] .topbar__list__link:focus {
      outline-offset: 0.125rem; }
  .topbar__list__item--current > .topbar__list__link::before {
    display: block; }

.topbar__list__link--teal {
  background-color: #0B7886;
  color: #fff;
  border-color: #0B7886; }
  .topbar__list__link--teal:hover, .topbar__list__link--teal:focus {
    background-color: #fff;
    color: #003478; }

.topbar__list__link--search {
  display: block;
  width: 1.25rem;
  padding: 0.8125rem 0;
  overflow: hidden; }
  .topbar__list__link--search::before {
    background: #003478; }

.topbar__list__link__inactive {
  display: block; }
  .search-active .topbar__list__link__inactive {
    display: none; }

.topbar__list__link__active {
  display: none; }
  .search-active .topbar__list__link__active {
    display: block; }

.topbar__dropdown {
  position: relative;
  float: left;
  color: #fff;
  font-family: "Whitney SSm A", "Whitney SSm B", sans-serif;
  z-index: 5; }

.topbar__dropdown__toggle {
  padding: 0.5625rem 0;
  float: left;
  color: inherit;
  font-size: 0.875rem;
  font-weight: 700; }
  .topbar__dropdown__toggle:hover {
    text-decoration: none; }
  .topbar__dropdown--active .topbar__dropdown__toggle {
    color: #003478; }

.topbar__dropdown__icon {
  width: 1.25rem;
  margin-left: 0.5rem;
  margin-top: 0.15rem;
  float: right; }
  .topbar__dropdown--active .topbar__dropdown__icon {
    transform: rotate(180deg); }

.topbar__dropdown__list {
  position: absolute;
  left: -999rem;
  position: absolute;
  top: -0.625rem;
  margin: 0;
  padding: 3.75rem 1rem 1.25rem;
  border-bottom-left-radius: 0.1875rem;
  border-bottom-right-radius: 0.1875rem;
  background: #f5f5f5;
  color: #003478;
  list-style: none;
  box-shadow: 0 0.375rem 0.375rem rgba(0, 0, 0, 0.1);
  visibility: hidden;
  z-index: -1; }
  .topbar__dropdown--active .topbar__dropdown__list {
    right: -1rem;
    left: -1rem;
    visibility: visible; }

.topbar__dropdown__item {
  margin: 0; }

.topbar__dropdown__link {
  display: block;
  padding: 0.65rem 0;
  font-weight: 700;
  color: inherit;
  text-transform: none;
  font-size: 0.75rem; }

.widget {
  margin-top: 5rem;
  margin-bottom: 5rem; }
  .widget > :first-child {
    margin-top: 0; }
  .widget > :last-child {
    margin-bottom: 0; }
  .widget img {
    margin-left: auto;
    margin-right: auto; }
  .widget p {
    margin-top: 1rem;
    margin-bottom: 1rem; }
    .widget p a:not([class]) {
      font-weight: 600;
      border-bottom: 1px solid #003478;
      padding-bottom: .125em; }
      .widget p a:not([class]):hover {
        border-color: #0B7886;
        text-decoration: none;
        color: #0B7886; }

.widget--large {
  font-size: 1.25rem;
  line-height: 1.4; }

.widget--small {
  margin-top: 2.5rem;
  margin-bottom: 2.5rem; }

.widget__heading {
  margin-top: 2.5rem;
  margin-bottom: 0.75rem;
  padding-top: 0.75rem;
  border-top: 0.25rem solid #57595B;
  font-family: "Whitney SSm A", "Whitney SSm B", sans-serif;
  font-size: 0.9375rem;
  font-weight: 600; }
  .widget__heading a {
    font-weight: 600; }
  .main .widget__heading {
    font-size: 1.75rem;
    font-weight: 400;
    line-height: 1.142;
    padding-top: 1.25rem; }

.widget__heading--small {
  padding-top: 0;
  border-top: 0; }

.widget__social {
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
  padding: 0;
  list-style: none; }
  .widget__social::before, .widget__social::after {
    display: table;
    width: 0;
    content: ''; }
  .widget__social::after {
    clear: both; }

.widget__social__item {
  margin: 0 1.25rem 0 0;
  float: left; }

.widget__social__link {
  display: block;
  width: 1.4rem;
  color: #003478; }
  .widget__social__link:hover, .widget__social__link:focus {
    color: #0B7886; }
