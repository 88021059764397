.card {
  padding: 1.25rem;
  background: $beige;
  color: $black-light;
  font-family: $sans-serif;

  .module--beige &,
  .module--gray-light & {
    background: $white;
  }
}

.card--alt {
  background: $white;
  color: $black-light;
  font-family: $sans-serif;
  padding: 1.25rem 0;
}

.card--flex {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.card,
.card--alt {
  a:not([class]) {
    display: inline-block;
    font-size: 1.25rem;
    line-height: 1.35;
    margin-bottom: 1rem;
  }
}


.card__tag {
  font-size: 0.875rem;
  font-weight: $book;
  margin-top: auto;
  text-decoration: underline;
  &:hover {
    color: $teal;
  }
}

.card__header {
  margin-bottom: 1.25rem;
  padding-bottom: 0.25rem;
  border-bottom: 0.25rem solid $gray-dark;
  .home & {
    border-color: $teal;
  }
}

.card__meta {
  font-family: $sans-serif;
  font-weight: $semibold;
  font-size: 0.875rem;
  text-transform: uppercase;
}
